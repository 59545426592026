import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SeatResponse, Seat} from './seat.model';
import {DatePipe} from '@angular/common';
import {environment} from '../environments/environment';


@Injectable()
export class SeatService {
  sharedData: any;



  constructor(private httpClient: HttpClient) {
  }

  setData(data) {
    this.sharedData = data;
  }

  getData() {
    console.log(this.sharedData);
    return this.sharedData;
  }

  getallseat(): Observable<any> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get_default'},
      data: [{}]
    };
    console.log(request);
    return this.httpClient.post<any>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  getseatchange(): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{
        id: this.sharedData
      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  getsearch(searching): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{
        seatCode: searching
      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  getseat(financeDate, seatid, searching): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{
        scheduleDate: financeDate,
        scheduleStatusId: seatid,
        seatName: searching
      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/Reservation', request);
  }

  getsummary(fromDate, toDate): Observable<SeatResponse> {
    // Javascript object

    const request = {
      apiRequest: {action: 'get'},
      data: [{
        scheduleDate: fromDate + '||' + toDate
      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/ReservationSummary', request);
  }

  // getslot(slottime,financeDate,period,seattype): Observable<SeatResponse> {
  //     // Javascript object
  //     const request = {
  //         apiRequest: { action: 'get' },
  //         data: [{
  //             scheduleDate:financeDate,
  //             slots: slottime,
  //             periodId:period,
  //             seatTypeId:seattype
  //         }]
  //     };
  //     console.log(request)
  //     return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  // }
  gethistolyuser(financeDate, username): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{
        scheduleDate: financeDate
        // username:username
      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/Reservation', request);
  }

  // getstatus(status: string): Observable<SeatResponse> {
  //     // Javascript object
  //     const request = {
  //         apiRequest: { action: 'get' },
  //         data: [{
  //             scheduleStatusId: status,

  //         }]
  //     };
  //     console.log(request)
  //     return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/Reservation', request);
  // }
  // getseatlogin(financeDate: string): Observable<SeatResponse> {
  //     // Javascript object
  //     const request = {
  //         apiRequest: { action: 'get' },
  //         data: [{
  //             scheduleDate: financeDate,
  //             // scheduleStatusId: "20000000-0000-0000-0000-000000000000"
  //         }]
  //     };
  //     console.log(request)
  //     return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/Reservation', request);
  // }
  get(): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{}]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  getseatSearch(date, period): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{
        scheduleDate: date,
        periodCode: period

      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  getseatSearchadmin(date, period, seatid): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{
        scheduleDate: date,
        periodCode: period,
        seatScheduleId: seatid

      }]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  reserveseat(data): Observable<SeatResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'reserve'},
      data: [
        data
      ]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  changereserveseat(seat: Seat): Observable<SeatResponse> {
    // Javascript object
    // var changeseat = [seat];
    const request = {
      apiRequest: {action: 'change'},
      data: [seat]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  updateseat(seat: Seat): Observable<SeatResponse> {
    // Javascript object
    // var updateseat = [seat];
    const request = {
      apiRequest: {action: 'update'},
      data: [seat]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  cancleseat(id): Observable<SeatResponse> {
    const request = {
      apiRequest: {action: 'cancel'},
      data: [{id: id}]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  resetseat(id): Observable<SeatResponse> {
    const request = {
      apiRequest: {action: 'reset'},
      data: [{id: id}]
    };
    console.log(request);
    return this.httpClient.post<SeatResponse>(environment.apiUrl + '/api/core/SeatSchedule', request);
  }

  seatzone(): Observable<any> {
    // Javascript object
    const request = {
      apiRequest: {action: 'list'},
      data: []
    };
    console.log(request);
    return this.httpClient.post<any>(environment.apiUrl + '/api/core/Seat', request);
  }

  login(data): Observable<any> {
    // Javascript object
    const request = {
      apiRequest: {action: 'login'},
      data: [data]
    };
    console.log(request);
    return this.httpClient.post<any>(environment.apiUrl + '/api/core/UserLogin', request);
  }

  loginemty(data): Observable<any> {
    // Javascript object
    const request = {
      apiRequest: {action: 'active'},
      data: [data]
    };
    console.log(request);
    return this.httpClient.post<any>(environment.apiUrl + '/api/core/UserLogin', request);
  }

  seatzonestatus(zonename: string): Observable<any> {
    // Javascript object
    const request = {
      apiRequest: {action: 'list'},
      data: [{name: zonename}]
    };
    console.log(request);
    return this.httpClient.post<any>(environment.apiUrl + '/api/core/Seat', request);
  }

  loginadmin(data): Observable<any> {
    // Javascript object
    const request = {
      apiRequest: {action: 'login'},
      data: [data]
    };
    console.log(request);
    return this.httpClient.post<any>(environment.apiUrl + '/api/core/User', request);
  }
}
