import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceResponse, Device } from './device.model';
import {environment} from '../environments/environment';

@Injectable()

export class DeviceService {

  sharedData: any

  constructor(private httpClient: HttpClient) {
  }
  setData(data) {
    this.sharedData = data
  }
  getData() {
    console.log(this.sharedData)
    return this.sharedData
  }
  getalldevice(): Observable<DeviceResponse> {
      // Javascript object
      const request = {
          apiRequest: { action: 'list' },
          data: [{ }]
      };
      console.log(request)
      return this.httpClient.post<DeviceResponse>(environment.apiUrl + '/api/core/Device', request);
  }
  getdevice(): Observable<DeviceResponse> {
    // Javascript object
    const request = {
        apiRequest: { action: 'get' },
        data: [{ id: this.sharedData }]
    };
    console.log(request)
    return this.httpClient.post<DeviceResponse>(environment.apiUrl + '/api/core/Device', request);
}
  search(typename): Observable<DeviceResponse> {
    // Javascript object
    const request = {
        apiRequest: { action: 'get' },
        data: [{ seatName: typename }]
    };
    console.log(request)
    return this.httpClient.post<DeviceResponse>(environment.apiUrl + '/api/core/Device', request);
}
updatedevice(device: Device): Observable<DeviceResponse> {
    // Javascript object
    var updatedevice =  [device];
    const request = {
        apiRequest: { action: 'update' },
        data: updatedevice
    };
    console.log(request)
    return this.httpClient.post<DeviceResponse>(environment.apiUrl + '/api/core/Device', request);
}
removeDevice(id): Observable<DeviceResponse> {
  // Javascript object
  const request = { apiRequest: { action: 'delete'}, data: [{id: id}]
  };
  return this.httpClient.post<DeviceResponse>(environment.apiUrl + '/api/core/Device', request);
}
}

