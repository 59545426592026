import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Zone} from 'src/app/zone.model';
import {ZoneService} from 'src/app/zone.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-zonestatus',
  templateUrl: './zonestatus.component.html',
  styleUrls: ['./zonestatus.component.css']
})
export class ZonestatusComponent implements OnInit {

  statuszone: any;
  zonenames: any;
  zones: Zone[] = [];
  mode = 0;

  constructor(private router: Router, private http: HttpClient, private zoneService: ZoneService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.zoneService.getallCloseSchedule().subscribe(result => {
      console.log(result);
      this.zones = result.data;
      // this.zone = this.zone.filter(x => x.zoneCode == 'A')
    });
  }

  zonestatus() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/ZoneStatus', data)
      .subscribe((res: any) => {
        this.statuszone = res.data;
        console.log(res.data);
      });
  }

  // checkzone(zone, check) {
  //   console.log(check)
  //   status = "";
  //   console.log(zone);
  //   if (check.target.checked) {
  //     zone.zoneStatusId = '10000000-0000-0000-0000-000000000000';
  //   }
  //   else {
  //     zone.zoneStatusId = '00000000-0000-0000-0000-000000000000';
  //   }
  //   zone.seatstatus =
  //     console.log(zone);
  //   this.zoneService.updatezonestatus(zone).subscribe(result => {
  //     console.log(zone);
  //     this.ngOnInit()
  //     alert('Success');
  //   });
  // }

  closezone() {
    this.mode = 1;
  }

  savezone() {
    this.mode = 0;
  }

  seatstatus() {
    return this.router.navigate(['seatstatus']);
  }

  createadmin() {
    return this.router.navigate(['createadmin']);
  }

  admin() {
    return this.router.navigate(['admin']);
  }

  device() {
    return this.router.navigate(['device']);
  }

  adminmanage() {
    return this.router.navigate(['adminmanage']);
  }

  zonestatuspage() {
    return this.router.navigate(['zonestatus']);
  }

  reservingadminmanage() {
    return this.router.navigate(['reservingadminmanage']);
  }

  logout() {
    return this.router.navigate(['login']);
  }
}
