import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-closeschedule-do',
  templateUrl: './closeschedule-do.component.html',
  styleUrls: ['./closeschedule-do.component.css']
})
export class ClosescheduleDoComponent implements OnInit {

  dataForm: ICloseScheduleDataForm = {};
  minDate: any;

  periods: any = {};
  zones: any = {};
  seats: any = {};

  dataZoneModel: any = [];
  dataZone: any = [];


  constructor(
    private router: Router,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {

  }


  async ngOnInit() {
    const current = new Date();
    this.dataForm = {
      fromPeriodId: '',
      toPeriodId: '',
    };
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.dataForm.fromDate = this.minDate;
    this.dataForm.toDate = this.minDate;
    try {

      this.periods = await this.http.post(environment.apiUrl + '/api/core/Period', {
        'apiRequest': {'action': 'list'}
      }).toPromise();

      this.zones = await this.http.post(environment.apiUrl + '/api/core/Zone', {
        'apiRequest': {'action': 'list'}
      }).toPromise();

      this.seats = await this.http.post(environment.apiUrl + '/api/core/Seat', {
        'apiRequest': {'action': 'list'}
      }).toPromise();


      this.zones.data.map((d) => {
        d.selectedAllGroup = 'group';
      });
      this.seats.data.map((d) => {
        d.isCheck = false;
      });
      console.log(this.zones.data);
    } catch (e) {
      console.log(e);
    }
  }

  onAddItem(ev) {
    console.log(ev);
    this.dataZone = ev;
    if (!this.dataZone) {
      return;
    }
    if (!this.seats) {
      return;
    }
    this.dataZone.map(item => {
      item.seats = this.seats.data.filter(f => f.zoneId === item.id);
    });
  }

  async onSubmit(form) {

    if (!form.valid) {
      return;
    }
    if (!this.dataForm.toDate
      || !this.dataForm.fromDate
      || !this.dataForm.fromPeriodId
      || !this.dataForm.toPeriodId
    ) {
      return;
    }

    if (!this.dataZoneModel[0]) {
      return;
    }

    // console.log(this.dataForm);
    // console.log(this.dataForm,this.dataZone);
    await this.onSave();


  }


  async onSave() {
    const fromDate = new Date();
    fromDate.setDate(this.dataForm.fromDate.day);
    fromDate.setMonth(this.dataForm.fromDate.month - 1);
    fromDate.setFullYear(this.dataForm.fromDate.year);
    const toDate = new Date();
    toDate.setDate(this.dataForm.toDate.day);
    toDate.setMonth(this.dataForm.toDate.month - 1);
    toDate.setFullYear(this.dataForm.toDate.year);
    const data = [];
    if (!this.dataZone) {
      return;
    }
    this.dataZone.map((zone) => {
      const item = {
        id: '',
        fromDate: fromDate,
        toDate: toDate,
        fromPeriodId: this.dataForm.fromPeriodId,
        toPeriodId: this.dataForm.toPeriodId,
        zoneId: zone.id,
        seatId: null,
      };
      const isCheck = zone.seats.find(f => f.isCheck);
      if (isCheck) {
        zone.seats.map((seat) => {
          if (seat.isCheck) {
            item.seatId = seat.id;
            data.push(item);
          }
        });
      }
      if (!isCheck) {
        data.push(item);
      }
    });
    console.log(data);

    const dataForm = {
      'apiRequest': {'action': 'new'},
      'data': data
    };
    try {
      const closeSchedule = await this.http.post(environment.apiUrl + '/api/core/CloseSchedule', dataForm).toPromise();
      await this.router.navigate(['home/closeschedule']);
    } catch (e) {
      console.log(e);
    }


  }


  onBack() {
    return this.router.navigate(['home/closeschedule']);
  }

  isFieldValid(form, field) {
    return field.errors && (field.dirty || field.touched || form.submitted);
  }
  isFieldValidData(form, data, field) {
    return !data[field] && form.submitted;
  }


}


export interface ICloseScheduleDataForm {
  id?: string;
  fromDate?: any;
  toDate?: any;
  fromPeriodId?: string;
  fromPeriodCode?: string;
  fromPeriodName?: string;
  toPeriodId?: string;
  toPeriodCode?: string;
  toPeriodName?: string;
  zoneId?: string;
  zoneCode?: string;
  zoneName?: string;
  seatId?: string;
  seatCode?: string;
  seatName?: string;
}
