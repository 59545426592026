import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SeatService } from '../seat.service';
import { HttpClient } from '@angular/common/http';
import { Zone } from 'src/app/zone.model';
import { ZoneService } from '../zone.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AuthService } from '../authen.service';
import {environment} from '../../environments/environment';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {

  zone: Zone[] = [];
  seatstatus: FormGroup;
  // zonestatus: FormGroup;
  seatdata: any;
  seattypes: any;
  isAdmin = false;
  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private zoneservice: ZoneService,
    private http: HttpClient,
    private authenService: AuthService) {
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);
  }

  ngOnInit() {

    this.zoneservice.getallzone().subscribe(result => {
      console.log(result);
      this.zone = result.data;
      this.zone = this.zone.filter(x => x.zoneCode == 'A')
      this.seattype()
    });
  }
  update(id) {
    console.log(id)
    this.router.navigate(['home/manage'])
    this.zoneservice.setData(id)
  }

  checkseat(seat, check) {
    console.log(check)
    status = "";
    console.log(seat);
    if (check.target.checked) {
      seat.seatStatusId = '10000000-0000-0000-0000-000000000000';
    }
    else {
      seat.seatStatusId = '00000000-0000-0000-0000-000000000000';
    }
    seat.seatstatus =
      console.log(seat);
    this.zoneservice.updatezone(seat).subscribe(result => {
      console.log(seat);
      // this.ngOnInit()
      alert('Success');
    });
  }
  selecttype(seat, seatType) {
    console.log(seat);
    console.log(seatType);
    seat.seatTypeId = seatType;
    console.log(seat);
    this.zoneservice.updatezone(seat).subscribe(result => {
      console.log(seat);
      //this.ngOnInit()
      // alert('Success');
    });
  }
  open(content) {
    this.modalService.open(content);
  }
  seattype() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatType', data)
      .subscribe((res: any) => {
        this.seattypes = res.data
        console.log(res.data);
      })
  }

  updatetype() {
    console.log(this.seatdata.getRawValue());
    this.zoneservice.updatezone(this.seatdata.getRawValue()).subscribe(result => {
      console.log(result);
      // alert('Success');
      // this.ngOnInit()
    });
  }
  // page
  zonea() {
    return this.router.navigate(['home/admin'])
  }
  zoneb() {
    return this.router.navigate(['home/zoneb'])
  }
  zonec() {
    return this.router.navigate(['home/zonec'])
  }
  zoned() {
    return this.router.navigate(['home/zoned'])
  }
  zonee() {
    return this.router.navigate(['home/zonee'])
  }
  zonef() {
    return this.router.navigate(['home/zonef'])
  }
  zoneg() {
    return this.router.navigate(['home/zoneg'])
  }
  zonep() {
    return this.router.navigate(['home/zonep'])
  }
  zones() {
    return this.router.navigate(['home/zones'])
  }

  admin() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
}

