import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Zone} from 'src/app/zone.model';
import {ZoneService} from 'src/app/zone.service';
import {AuthService} from '../authen.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-closeschedule',
  templateUrl: './closeschedule.component.html',
  styleUrls: ['./closeschedule.component.css']
})
export class ClosescheduleComponent implements OnInit {

  statuszone;
  zonenames;
  zones: Zone[] = [];
  mode = 0;
  // }
  closeschedule_id;

  constructor(private router: Router, private modalService: NgbModal, private http: HttpClient, private zoneService: ZoneService, private formBuilder: FormBuilder,
              private authenService: AuthService) {
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);
  }

  ngOnInit() {
    this.zoneService.getallCloseSchedule().subscribe(result => {
      console.log(result);
      this.zones = result.data;
      // this.zone = this.zone.filter(x => x.zoneCode == 'A')
    });
  }

  // checkzone(zone, check) {
  //   console.log(check)
  //   status = "";
  //   console.log(zone);
  //   if (check.target.checked) {
  //     zone.zoneStatusId = '10000000-0000-0000-0000-000000000000';
  //   }
  //   else {
  //     zone.zoneStatusId = '00000000-0000-0000-0000-000000000000';
  //   }
  //   zone.seatstatus =
  //     console.log(zone);
  //   this.zoneService.updatezonestatus(zone).subscribe(result => {
  //     console.log(zone);
  //     this.ngOnInit()
  //     alert('Success');
  //   });

  zonestatus() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/ZoneStatus', data)
      .subscribe((res: any) => {
        this.statuszone = res.data;
        console.log(res.data);
      });
  }

  remove() {
    this.zoneService.removeCloseSchedule(this.closeschedule_id).subscribe(result => {
      console.log(result);
      // alert('Delete Data!')
      this.ngOnInit();
    });
  }

  open(content, id) {
    console.log(id);
    this.closeschedule_id = id;
    this.modalService.open(content);
  }

  closezone() {
    this.mode = 1;
  }

  savezone() {
    this.mode = 0;
  }

  closeschedulemanage() {
    return this.router.navigate(['home/closeschedulemanage']);
  }

  createadmin() {
    return this.router.navigate(['home/createadmin']);
  }

  admin() {
    return this.router.navigate(['home/admin']);
  }

  device() {
    return this.router.navigate(['home/device']);
  }

  adminmanage() {
    return this.router.navigate(['home/adminmanage']);
  }

  closeschedulepage() {
    return this.router.navigate(['home/closeschedule']);
  }

  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage']);
  }

  logout() {
    return this.router.navigate(['login']);
  }

  loginuser() {
    return this.router.navigate(['loginuser']);
  }
}
