import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {
  private subscription: Subscription;
  message: any;
  constructor(private alertService: AlertService) { }
  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => { 
        this.message = message; 
    });
}

ngOnDestroy() {
    this.subscription.unsubscribe();
}
}
