import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SeatService } from 'src/app/seat.service';
import { Zone } from 'src/app/zone.model';
import { ZoneService } from 'src/app/zone.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {
  isFormReady = false;
  zones: FormGroup;
  zonedata:any;
  seatdata: any;
  zone: Zone[] = [];
  seatzone:any;
  statusseat:any;
  statuszone:any;
  zonenames:any;

  constructor(private router: Router, private http: HttpClient, private zoneService: ZoneService, private formBuilder: FormBuilder) {
    this.zoneService.getzone().subscribe(result => {
      console.log(result)
      this.zone = result.data;
      this.zones = this.formBuilder.group(result.data[0]);
      // this.zones = this.formBuilder.group({
      //   id: [""],
      //   code: [""],
      //   name: [""],
      //   zoneId: [""],
      //   zoneCode: [""],
      //   zoneName: [""],
      //   zoneStatusId: [""],
      //   zoneStatusCode: [""],
      //   zoneStatusName: [""],
      //   seatStatusId: [""],
      //   seatStatusCode: [""],
      //   seatStatusName: [""],
      //   displayX: 0,
      //   displayY: 0,
      //   seatTypeId: [""],
      //   seatTypeCode: [""],
      //   seatTypeName: [""]
      // });
      // console.log(this.zones);
      // this.zones.patchValue(result.data[0]);
      // console.log(this.zones);

      this.zonedata = this.zone.find(f => f.id == this.zoneService.getData())
      console.log(this.zonedata)
      this.isFormReady = true;
    });
  }

  ngOnInit() {
    this.seattype()
    this.seatstatus()
    console.log(this.zone)
    this.zonestatus()
    this.zonename()
  }
  seattype(){
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatType', data)
      .subscribe((res: any) => {
        this.seatdata = res.data
        console.log(res.data);
      })
  }
  seatstatus(){
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatStatus', data)
      .subscribe((res: any) => {
        this.statusseat = res.data
        console.log(res.data);
      })
  }
  zonestatus(){
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/ZoneStatus', data)
      .subscribe((res: any) => {
        this.statuszone = res.data
        console.log(res.data);
      })
  }

  zonename(){
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Zone', data)
      .subscribe((res: any) => {
        this.zonenames = res.data
        console.log(res.data);
      })
  }
  update() {
    // this.submitted = true;
    // {
    //   if (this.registeremployee.invalid) {
    //     return;
    //   }
    console.log(this.zones.getRawValue());
    this.zoneService.updatezone(this.zones.getRawValue()).subscribe(result => {
      console.log(result);
      this.router.navigate(['/admin'])
      alert('Success');
    });
  }
}
