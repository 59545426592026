import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SeatService} from '../seat.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../authen.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginusers: FormGroup;
  isAdmin = false;
  submitted = false;
  submessage: any;
  message: any;

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private seatservice: SeatService,
              private authenService: AuthService,
              private http: HttpClient) {
    // const request = {apiRequest:{action:'login'},data:[{userName:'userx',password:'password@1'}]};
    // this.http.post('http://roomcontrol.ctt-center.com/api/core/user',request).subscribe(data=>{
    //   this.isAdmin = data.data[0].isAdmin;
    // alert(data.apiResponse.desc)
    //   console.log(data);
    // });

  }

  get f() {
    return this.loginusers.controls;
  }

  ngOnInit() {
    this.create();
  }

  loginadmin(content) {
    this.submitted = true;
    {
      if (this.loginusers.invalid) {
        return;
      }
      console.log(this.loginusers.value);
      this.seatservice.loginadmin(this.loginusers.value).subscribe(data => {
        console.log(data);
        if (data.apiResponse.id == 0) {


          this.authenService.setLogin(data.data[0].userName, data.data[0].isAdmin);

          this.authenService.userName = data.data[0].userName;
          this.authenService.isAdmin = data.data[0].isAdmin;
          this.message = 'Login สำเร็จ';
          this.submessage = 'เข้าใช้งานเรียบร้อย';
          this.router.navigate(['home/reservingadminmanage']);
          this.open(content);
        } else if (data.apiResponse.desc == 'WRONG_USER_OR_PASSWORD') {
          this.message = 'Login ไม่สำเร็จ';
          this.submessage = 'ตรวจสอบการสะกด User และ Password อีกครั้ง';
          this.open(content);
          // alert(data.apiResponse.desc)
        }
        // else {
        //   alert(data.apiResponse.desc)
        // }
      });
    }
  }

  create() {
    this.loginusers = this.formBuilder.group({

      userName: ['', Validators.required],
      password: ['', Validators.required]

    });
  }

  loginuser() {
    return this.router.navigate(['loginuser']);
  }

  open(content) {
    this.modalService.open(content);
  }
}
