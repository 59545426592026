import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AdminService } from '../admin.service';
import { AuthService } from '../authen.service';


@Component({
  selector: 'app-adminmanage',
  templateUrl: './adminmanage.component.html',
  styleUrls: ['./adminmanage.component.css']
})
export class AdminmanageComponent implements OnInit {
  adminlists: any;
  superadminlists: any;
  isLoggedIn:any;
  userName:any;
  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private adminservice: AdminService, private http: HttpClient,
    private authenService:AuthService) {
      this.isLoggedIn = this.authenService.isLoggedIn;
      this.userName = this.authenService.userName
      console.log(this.authenService.userName);
      console.log(this.authenService.isLoggedIn);
  }

  ngOnInit() {
    this.superadminlist()
    this.adminlist()
  }
  superadminlist() {
    this.adminservice.adminlist().subscribe(result => {
      console.log(result);
      this.superadminlists = result.data;
      this.superadminlists = this.superadminlists.filter(x => x.isAdmin == true)
    });
  }
  adminlist() {
    this.adminservice.adminlist().subscribe(result => {
      console.log(result);
      this.adminlists = result.data;
      this.adminlists = this.adminlists.filter(x => x.isAdmin == false)
    });
  }
  update(id) {
    console.log(id)
    this.router.navigate(['home/editadmin'])
    this.adminservice.setData(id)
  }
admin_id : any
  remove() {
    // var dialog = confirm(admin.firstName)
    // if(dialog == true){ 
    this.adminservice.removeadmin(this.admin_id).subscribe(result => {
      console.log(result);
      
      // alert(result.apiResponse.desc)
      this.ngOnInit()
    });
      // }
  }
  createadmin() {
    return this.router.navigate(['home/createadmin'])
  }
  admin() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  open(content, id) {
    console.log(id)
    this.admin_id = id
    this.modalService.open(content);
  }
}
