import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AdminResponse, Admin} from './admin.model';
import {environment} from '../environments/environment';

@Injectable()
export class AdminService {

  sharedData: any;

  constructor(private httpClient: HttpClient) {
  }

  setData(data) {
    this.sharedData = data;
  }

  getData() {
    console.log(this.sharedData);
    return this.sharedData;
  }

  getallCloseSchedule(): Observable<AdminResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'list'},
      data: [{}]
    };
    console.log(request);
    return this.httpClient.post<AdminResponse>(environment.apiUrl + '/api/core/CloseSchedule', request);
  }

  adminlist(): Observable<AdminResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'list'},
      data: [{}]
    };
    console.log(request);
    return this.httpClient.post<AdminResponse>(environment.apiUrl + '/api/core/User', request);
  }

  getadmin(): Observable<AdminResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'get'},
      data: [{id: this.sharedData}]
    };
    console.log(request);
    return this.httpClient.post<AdminResponse>(environment.apiUrl + '/api/core/User', request);
  }

  updateuseradmin(admin: Admin): Observable<AdminResponse> {
    // Javascript object
    var updateadmin = [admin];
    const request = {
      apiRequest: {action: 'update'},
      data: updateadmin
    };
    console.log(request);
    return this.httpClient.post<AdminResponse>(environment.apiUrl + '/api/core/User', request);
  }

  removeadmin(id): Observable<AdminResponse> {
    // Javascript object
    const request = {
      apiRequest: {action: 'delete'}, data: [{id: id}]
    };
    return this.httpClient.post<AdminResponse>(environment.apiUrl + '/api/core/User', request);
  }

}
