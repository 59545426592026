import { Component, OnInit } from '@angular/core';
import { Device } from 'src/app/device.model';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DeviceService } from 'src/app/device.service';
import { AuthService } from 'src/app/authen.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-managedevice',
  templateUrl: './managedevice.component.html',
  styleUrls: ['./managedevice.component.css']
})
export class ManagedeviceComponent implements OnInit {
  device: Device[] = [];
  devices: FormGroup;
  devicedata: any;
  isFormReady = false;
  seatdata: any;
  devicetype:any;

  constructor(private router: Router,private modalService: NgbModal, private formBuilder: FormBuilder, private deviceservice: DeviceService
    , private http: HttpClient,
    private authenService:AuthService) {
      console.log(this.authenService.userName);
      // console.log(this.authenService.isAdmin);

    this.deviceservice.getdevice().subscribe(result => {
      console.log(result)
      this.device = result.data;
      this.devices = this.formBuilder.group(result.data[0]);

      console.log(this.devices);
      this.devices.patchValue(result.data[0]);
      console.log(this.devices);

      this.devicedata = this.device.find(f => f.id == this.deviceservice.getData())
      console.log(this.devicedata)
      this.isFormReady = true;
    });
  }


  ngOnInit() {
    this.seat()
    this.getdevicetype()
  }
  seat() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatdata = res.data
        console.log(res.data);
      })
  }
  getdevicetype() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/DeviceType', data)
      .subscribe((res: any) => {
        this.devicetype = res.data
        console.log(res.data);
      })
  }
  devicetypedata = [{
    id: 1, type: ['เครื่องคอมพิวเตอร์']
  },
  {
    id: 2, type: ['แสกนเนอร์']
  },
  {
    id: 3, type: ['โต๊ะเปล่า']
  }
  ];
  update() {
    console.log(this.devices.getRawValue());
    this.deviceservice.updatedevice(this.devices.getRawValue()).subscribe(result => {
      console.log(result);
      this.router.navigate(['home/device'])
      // alert('Success');
    });
  }
  admin() {
    return this.router.navigate(['home/admin'])
  }
  devicepage() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  logout(){
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  open(content) {
    this.modalService.open(content, { size: 'lg' });
  }
}
