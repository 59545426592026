import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ZoneService} from 'src/app/zone.service';
import {Zone} from 'src/app/zone.model';
import {DatePipe} from '@angular/common';
import {AuthService} from 'src/app/authen.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-closeschedulemanage',
  templateUrl: './closeschedulemanage.component.html',
  styleUrls: ['./closeschedulemanage.component.css']
})
export class CloseschedulemanageComponent implements OnInit {
  zones: Zone[] = [];
  data: FormArray;
  zonenames: any;
  seatnames: any;
  periods: any;
  modal: any;
  closeschedule: FormGroup;
  submitted = false;
  model: Date;
  minDate: any;
  selectAll: boolean = false;
  defaultDateAndTime = {
    fromDate: null,
    fromPeriodId: '',
    toDate: null,
    toPeriodId: '',
    seatId: ''
  };


  constructor(private router: Router, private modalService: NgbModal, private http: HttpClient, private zoneservice: ZoneService, private formBuilder: FormBuilder, public datePipe: DatePipe,
              private authenService: AuthService) {
    console.log(this.authenService.userName);
    console.log(this.authenService.isAdmin);
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.defaultDateAndTime.fromDate = this.minDate;
    this.defaultDateAndTime.toDate = this.minDate;
  }

  get f() {
    return this.closeschedule.controls;
  }

  ngOnInit() {
    this.zoneservice.getallzone().subscribe(result => {
      console.log(result);
      this.zones = result.data;
      this.zones = this.zones.filter(x => x.zoneCode === 'A');
      // this.seattypes()
    });
    this.createformstatus();
    this.period();
    this.zonename();
    this.seatname();
    // this.seatnames = this.zonenames.filter(x => x.zonenames.name == this.seatnames.name)[0].seatnames;
  }

  // createcloseschedule() {
  //   return this.formBuilder.group({
  //     id: '',
  //     fromDate: [this.minDate, Validators.required],
  //     toDate: [this.minDate, Validators.required],
  //     fromPeriodId: ['', Validators.required],
  //     toPeriodId: ['', Validators.required],
  //     zoneId: ['', Validators.required],
  //     seatId: ['']
  //   });

  createformstatus() {
    var date = this.datePipe.transform(new Date(), 'y-MM-dd');
    this.closeschedule = this.formBuilder.group({
      data: this.formBuilder.array([])
    });
    console.log(this.closeschedule.value);
  }

  // }
  addCloseschedule(zoneId) {
    const fromDate = new Date();
    fromDate.setDate(this.defaultDateAndTime.fromDate.day);
    fromDate.setMonth(this.defaultDateAndTime.fromDate.month - 1);
    fromDate.setFullYear(this.defaultDateAndTime.fromDate.year);
    const toDate = new Date();
    toDate.setDate(this.defaultDateAndTime.toDate.day);
    toDate.setMonth(this.defaultDateAndTime.toDate.month - 1);
    toDate.setFullYear(this.defaultDateAndTime.toDate.year);
    return this.formBuilder.group({
      id: '',
      fromDate: [fromDate, Validators.required],
      toDate: [toDate, Validators.required],
      fromPeriodId: [this.defaultDateAndTime.fromPeriodId, Validators.required],
      toPeriodId: [this.defaultDateAndTime.toPeriodId, Validators.required],
      zoneId: [zoneId ? zoneId : null],
      seatId: [this.defaultDateAndTime.seatId]
    });
  }

  check(id: number) {
    let index = this.closeschedule.value.data.findIndex(x => x.zoneId === id);
    if (index != -1) {

      return true;
    } else {
      return false;
    }

  }

  selectedAll(event) {
    console.log(event);
    this.selectAll = event;
    if (this.selectAll) {
      this.closeschedule.value.data.forEach((element, index) => {
        (<FormArray>this.closeschedule.get('data')).removeAt(0);
      });
      this.zonenames.forEach(element => {
        (<FormArray>this.closeschedule.get('data')).push(this.addCloseschedule(element.id));
      });
    } else {
      this.closeschedule.value.data.forEach((element, index) => {
        (<FormArray>this.closeschedule.get('data')).removeAt(0);
      });
      this.getDataform(this.defaultDateAndTime);
    }
  }

  getDataform(data) {
    (<FormArray>this.closeschedule.get('data')).push(this.addCloseschedule(data));
    console.log('เพิ่มข้อมูล');
  }

  checkremove(zoneData: any, e) {
    let index = this.closeschedule.value.data.findIndex(x => x.zoneId === zoneData.id);
    console.log(e);
    if (e.target.checked) {
      (<FormArray>this.closeschedule.get('data')).push(this.addCloseschedule(zoneData.id));
      console.log('เพิ่มข้อมูล');
      console.log(zoneData);
      return true;
    } else {
      (<FormArray>this.closeschedule.get('data')).removeAt(index);
      console.log('ลบข้อมูล');
      return false;
    }
  }

  closestatus() {
    console.log(1);
    this.submitted = true;

    // if (this.closeschedule.invalid) {
    //   return;
    // }
    // var date = this.defaultDateAndTime
    // let myDate = new Date(date.fromDate.year, date.fromDate.month - 1, date.fromDate.day);
    // date.fromDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
    // console.log(date);
    // var date = this.defaultDateAndTime
    // let myDate2 = new Date(date.toDate.year, date.toDate.month - 1, date.toDate.day);
    // date.toDate = this.datePipe.transform(myDate2, 'yyyy-MM-dd');
    // console.log(date);



    const data = {
      'apiRequest': {'action': 'new'}
      , 'data': this.closeschedule.value.data
    };
    console.log('----', this.closeschedule.value.data);

    this.http.post(environment.apiUrl + '/api/core/CloseSchedule', data)
      .subscribe((res: any) => {
        this.router.navigate(['home/closeschedule']);
        console.log(res.data);
        this.modal.close();
        // alert('success')
      });
  }


  open(content) {
   this.modal = this.modalService.open(content, {size: 'lg'});
  }

  shareCheckedList(item: any[]) {
    console.log(item);
  }

  shareIndividualCheckedList(item: {}) {
    console.log(item);
  }

  // checkseat(seat, check) {
  //   console.log(check)
  //   status = "";
  //   console.log(seat);
  //   if (check.target.checked) {
  //     seat.seatStatusId = '10000000-0000-0000-0000-000000000000';
  //   }
  //   else {
  //     seat.seatStatusId = '00000000-0000-0000-0000-000000000000';
  //   }
  //   seat.seatstatus =
  //     console.log(seat);
  //   this.zoneservice.updatezone(seat).subscribe(result => {
  //     console.log(seat);
  //     this.ngOnInit()
  //     alert('Success');
  //   });
  // }


  zonename() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/Zone', data)
      .subscribe((res: any) => {
        this.zonenames = res.data;
        console.log(res.data);
      });
  }

  seatname() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatnames = res.data;
        console.log(res.data);
      });
  }

  period() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/Period', data)
      .subscribe((res: any) => {
        this.periods = res.data;
        console.log(res.data);
      });
  }

  createadmin() {
    return this.router.navigate(['home/createadmin']);
  }

  admin() {
    return this.router.navigate(['home/admin']);
  }

  device() {
    return this.router.navigate(['home/device']);
  }

  adminmanage() {
    return this.router.navigate(['home/adminmanage']);
  }

  closeschedulepage() {
    return this.router.navigate(['home/closeschedule']);
  }

  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage']);
  }

  logout() {
    return this.router.navigate(['login']);
  }

  loginuser() {
    return this.router.navigate(['loginuser']);
  }
}
