import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ZoneResponse, Zone } from './zone.model';
import {environment} from '../environments/environment';


@Injectable()
export class ZoneService {

    sharedData: any

    constructor(private httpClient: HttpClient) {
    }
    setData(data) {
        this.sharedData = data
    }
    getData() {
        console.log(this.sharedData)
        return this.sharedData
    }
    getallzone(): Observable<ZoneResponse> {
        // Javascript object
        const request = {
            apiRequest: { action: 'list' },
            data: [{}]
        };
        console.log(request)
        return this.httpClient.post<ZoneResponse>(environment.apiUrl + '/api/core/Seat', request);
    }
    zonename(): Observable<ZoneResponse> {
        // Javascript object
        const request = {
            apiRequest: { action: 'list' }
            ,
            data: [{}]
        };
        console.log(request)
        return this.httpClient.post<ZoneResponse>(environment.apiUrl + '/api/core/Zone', request);
    }
    getzone(): Observable<ZoneResponse> {
        // Javascript object
        const request = {
            apiRequest: { action: 'get' },
            data: [{ id: this.sharedData }]
        };
        console.log(request)
        return this.httpClient.post<ZoneResponse>(environment.apiUrl + '/api/core/Seat', request);
    }
    getzonestatus(statusseat): Observable<ZoneResponse> {
        // Javascript object
        const request = {
            apiRequest: { action: 'get' },
            data: [{
                id: this.sharedData,
                seatStatusId: statusseat
            }]
        };
        console.log(request)
        return this.httpClient.post<ZoneResponse>(environment.apiUrl + '/api/core/Seat', request);
    }
    updatezone(zone: Zone): Observable<ZoneResponse> {
        // Javascript object
        var updatezone = [zone];
        const request = {
            apiRequest: { action: 'update' },
            data: updatezone
        };
        console.log(request)
        return this.httpClient.post<ZoneResponse>(environment.apiUrl + '/api/core/Seat', request);
    }
    updateclosestatus(zone: Zone): Observable<ZoneResponse> {
        // Javascript object
        var updatestatus = [zone];
        const request = {
            apiRequest: { action: 'update' },
            data: updatestatus
        };
        console.log(request)
        return this.httpClient.post<ZoneResponse>(environment.apiUrl + '/api/core/CloseSchedule', request);
    }
    getallCloseSchedule(): Observable<any> {
        // Javascript object
        const request = {
          apiRequest: { action: 'list' },
          data: [{}]
        };
        console.log(request)
        return this.httpClient.post<any>(environment.apiUrl + '/api/core/CloseSchedule', request);
      }
      removeCloseSchedule(id): Observable<any> {
        // Javascript object
        const request = { apiRequest: { action: 'delete'}, data: [{id: id}]
        };
        return this.httpClient.post<any>(environment.apiUrl + '/api/core/CloseSchedule', request);
      }
}
