import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ZoneService } from 'src/app/zone.service';
import { Zone } from 'src/app/zone.model';
import { DatePipe } from '@angular/common';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-seatstatus',
  templateUrl: './seatstatus.component.html',
  styleUrls: ['./seatstatus.component.css']
})
export class SeatstatusComponent implements OnInit {
  zones: Zone[] = [];
  zonenames: any;
  seatnames: any;
  periods: any;
  closeschedule: FormGroup;
  constructor(private router: Router, private http: HttpClient, private zoneservice: ZoneService, private formBuilder: FormBuilder,public datePipe: DatePipe) { }

  ngOnInit() {
    this.zoneservice.getallzone().subscribe(result => {
      console.log(result);
      this.zones = result.data;
      this.zones = this.zones.filter(x => x.zoneCode == 'A')
      // this.seattypes()
    });
    this.createformstatus()
    this.period()
    this.zonename()
    this.seatname()
    // this.seatnames = this.zonenames.filter(x => x.zonenames.name == this.seatnames.name)[0].seatnames;
  }

  createformstatus() {
    var date = this.datePipe.transform(new Date(), 'y-MM-dd')
    this.closeschedule = this.formBuilder.group({
      id: "",
      fromDate: [date, Validators.required],
      toDate: [date, Validators.required],
      fromPeriodId: "",
      fromPeriodCode: "",
      fromPeriodName: "",
      toPeriodId: "",
      toPeriodCode: "",
      toPeriodName: "",
      zoneId: "",
      zoneCode: "",
      zoneName: "",
      seatId: "",
      seatCode: "",
      seatName: ""
    })
  }
  closestatus() {
    var data = {
      'apiRequest': { 'action': 'new' }
      , 'data': [this.closeschedule.value]
    }
    this.http.post(environment.apiUrl + '/api/core/CloseSchedule', data)
      .subscribe((res: any) => {
        this.router.navigate(['/zonestatus'])
        console.log(res.data);
        alert('success')
      })
  }

  // checkseat(seat, check) {
  //   console.log(check)
  //   status = "";
  //   console.log(seat);
  //   if (check.target.checked) {
  //     seat.seatStatusId = '10000000-0000-0000-0000-000000000000';
  //   }
  //   else {
  //     seat.seatStatusId = '00000000-0000-0000-0000-000000000000';
  //   }
  //   seat.seatstatus =
  //     console.log(seat);
  //   this.zoneservice.updatezone(seat).subscribe(result => {
  //     console.log(seat);
  //     this.ngOnInit()
  //     alert('Success');
  //   });
  // }
  zonename() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Zone', data)
      .subscribe((res: any) => {
        this.zonenames = res.data
        console.log(res.data);
      })
  }
  seatname() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatnames = res.data
        console.log(res.data);
      })
  }
  period() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Period', data)
      .subscribe((res: any) => {
        this.periods = res.data
        console.log(res.data);
      })
  }
  createadmin() {
    return this.router.navigate(['createadmin'])
  }
  admin() {
    return this.router.navigate(['admin'])
  }
  device() {
    return this.router.navigate(['device'])
  }
  adminmanage() {
    return this.router.navigate(['adminmanage'])
  }
  zonestatuspage() {
    return this.router.navigate(['zonestatus'])
  }
  reservingadminmanage() {
    return this.router.navigate(['reservingadminmanage'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
}
