import {Component, OnInit} from '@angular/core';
import {Device} from 'src/app/device.model';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DeviceService} from 'src/app/device.service';
import {AuthService} from 'src/app/authen.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-createdevice',
  templateUrl: './createdevice.component.html',
  styleUrls: ['./createdevice.component.css']
})
export class CreatedeviceComponent implements OnInit {

  device: Device[] = [];
  devices: FormGroup;
  devicedata: any;
  seatdata: any;
  devicetype: any;
  submitted = false;
  devicetypedata = [{
    id: 1, type: ['เครื่องคอมพิวเตอร์']
  },
    {
      id: 2, type: ['แสกนเนอร์']
    },
    {
      id: 3, type: ['โต๊ะเปล่า']
    }
  ];

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private deviceservice: DeviceService
    , private http: HttpClient,
              private authenService: AuthService) {
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);
  }

  get f() {
    return this.devices.controls;
  }

  ngOnInit() {
    this.create();
    this.seat();
    this.getdevicetype();
  }

  create() {
    this.devices = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      deviceTypeId: ['', Validators.required],
      deviceTypeCode: [''],
      deviceTypeName: [''],
      assetCode: [''],
      serviceTo: [''],
      ip: [''],
      mac: [''],
      seatId: ['', Validators.required],
      seatCode: [''],
      seatName: ['']
    });
  }

  news() {
    this.submitted = true;
    {
      if (this.devices.invalid) {
        return;
      }
      var data = {
        'apiRequest': {'action': 'new'}
        , 'data': [this.devices.value]
      };
      this.http.post(environment.apiUrl + '/api/core/Device', data)
        .subscribe((res: any) => {
          this.router.navigate(['home/device']);
          console.log(res.data);
          // alert('success')
        });
    }
  }

  seat() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatdata = res.data;
        console.log(res.data);
      });
  }

  getdevicetype() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/DeviceType', data)
      .subscribe((res: any) => {
        this.devicetype = res.data;
        console.log(res.data);
      });
  }

  admin() {
    return this.router.navigate(['home/admin']);
  }

  devicepage() {
    return this.router.navigate(['home/device']);
  }

  adminmanage() {
    return this.router.navigate(['home/adminmanage']);
  }

  closeschedule() {
    return this.router.navigate(['home/closeschedule']);
  }

  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage']);
  }

  logout() {
    return this.router.navigate(['login']);
  }

  loginuser() {
    return this.router.navigate(['loginuser']);
  }

  open(content) {
    this.modalService.open(content, {size: 'lg'});
  }
}
