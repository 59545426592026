import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SeatService } from '../seat.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { fromEvent } from 'rxjs';
import {environment} from '../../environments/environment';
@Component({
  selector: 'app-reservingmanageseatschedule',
  templateUrl: './reservingmanageseatschedule.component.html',
  styleUrls: ['./reservingmanageseatschedule.component.css']
})
export class ReservingmanageseatscheduleComponent implements OnInit {
  mode = 0;
  histories: any;
  lastSelectedSeat: any;
  seatdata: any
  typedata: any
  perioddata: any;
  isFormReady = false;
  reload = true;
  seletedDate: any;
  minDate: any;
  scheduleStatusId: any;
  scheduleDateform: any
  searching: any
  seatstatusdata:any
  selectedHistory: any;
  currentCursor = "default";
  currentSeat = '00000000-0000-0000-0000-000000000000';
  constructor(private router: Router,private http: HttpClient,private seatservice: SeatService, private modalService: NgbModal,private formBuilder: FormBuilder) {
    this.seatInfoForm = formBuilder.group({})
    this.isFormReady = true;
    this.getFinancialData(this.searching)
  }
  changeScheduleStatuss(event) {
    console.log(event)
    this.searching = event.target.value;
  }

  search(value) {
    console.log(value);
    this.searching = value;
    this.getFinancialData(this.searching)
  }

  getFinancialData(searching) {
    console.log('Change Date');
    this.seatservice.getsearch(searching).subscribe(result => {
      console.log(result);
      this.mode = 0;
      this.seatstatusdata = result.data;
      this.seatstatusdata = this.seatstatusdata.filter(x => x.scheduleStatusName == "Occupied")
    });
  }

  seatstatus() {
    console.log('Change Date');
    this.seatservice.getallseat().subscribe(result => {
      console.log(result);
      this.mode = 0
      this.seatstatusdata = result.data;
      this.seatstatusdata = this.seatstatusdata.filter(x => x.scheduleStatusName == "Occupied")
    });
  }

  @ViewChild("divCanvas") divCanvas: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  cx: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;
  ratio: number;
  seats: any;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    console.log(window.innerWidth);
    console.log(this.divCanvas.nativeElement.offsetWidth);
    console.log(this.divCanvas.nativeElement.offsetHeight);

    //if (this.firstLoad) {
    var screen_ratio = 2;
    if (window.innerWidth < 768) {
      screen_ratio = 1
    }
    else {
      screen_ratio = 2;
    }
    const size = window.innerHeight < window.innerWidth / screen_ratio ? window.innerHeight : window.innerWidth / screen_ratio;
    if (window.innerHeight < (window.innerWidth / screen_ratio)) {

      this.canvasEl.height = size; // window.innerHeight;
      this.canvasEl.width = size; // window.innerWidth/screen_ratio;
      this.ratio = window.innerHeight / 1000;
    }
    else {
      this.canvasEl.height = size;// window.innerHeight;
      this.canvasEl.width = size;// window.innerWidth/screen_ratio;
      this.ratio = (window.innerWidth / screen_ratio) / 1000;
    }

    //console.log(this.divCanvas.nativeElement.offsetWidth);
    if (this.seats) {
      this.seats.forEach(seat => {
        this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
      });
    }
    if (this.lastSelectedSeat) {
      console.log(this.lastSelectedSeat);
      //this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
      this.selectedDraw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio);
    }

    // this.ngAfterViewInit()
    console.log(this.ratio);
    // this.can
    // console.log(window.innerWidth);
    // this.innerWidth = window.innerWidth;
  }
  ngOnInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');
    fromEvent(this.canvasEl, 'mousemove').subscribe(location => {
      // console.log("Moves");
      //console.log(this.ratio);
      var match = false;
      this.seats.forEach(element => {

        //console.log(element);
        if (!match) {
          if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
            (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
            if (this.currentSeat == element.id) {
              match = true;
            }
            else {
              this.currentSeat = element.id;
              if (this.currentCursor != 'pointer') {
                console.log(this.currentCursor);
                this.canvasEl.style.cursor = 'pointer';
                this.currentCursor = 'pointer';
                match = true;
                console.log('Change cursor to point');
              }
              else {
                console.log('Already pointer');
              }
            }

          }
          else {


          }
        }


      });
      if (!match) {
        if (this.currentCursor != 'default') {
          this.canvasEl.style.cursor = 'default';
          this.currentCursor = 'default';
          this.currentSeat = '00000000-0000-0000-0000-000000000000';
        }
      }

    }
    );
    fromEvent(this.canvasEl, 'mousedown').subscribe(location => {
      //console.log(location);
      //console.log(this.ratio);
      this.seats.forEach(element => {
        //console.log(element);

        if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
          (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
          console.log(element);
          if (element.scheduleStatusId == '00000000-0000-0000-0000-000000000000') {
            this.seatInfoForm.patchValue({
              id: element.id,
              seatId: element.seatId,
              seatCode: element.seatCode,
              seatName: element.seatName,
              // scheduleDate: element.scheduleDate,
              // periodId: element.periodId,
              // periodCode: element.periodCode,
              // periodName: element.periodName,
              // availablePeriod: element.availablePeriod,
              scheduleStatusId: element.scheduleStatusId,
              scheduleStatusCode: element.scheduleStatusCode,
              scheduleStatusName: element.scheduleStatusName,
              displayX: element.displayX,
              displayY: element.displayY,
              // userName: element.userName
            })
            if (this.lastSelectedSeat) {
              this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
            }
            this.selectedDraw(element.displayX * this.ratio, element.displayY * this.ratio);
            this.lastSelectedSeat = element;
          }
        }
      });
    }
    );
    this.onResize();
    this.periods();
    this.seattypes();
    this.seatstatus();
    this.getFinancialData(this.searching)
  }
  selectedDraw(x, y) {
    console.log(x);
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = 'yellow'
    this.cx.fill();
  }

  draw(x, y, draw) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    switch (draw.scheduleStatusId) {
      case '10000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'red'
        break;
      case '00000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = '#4fcc25'
        break;
      case '20000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'blue'
        break;
      case '30000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'gray'
        break;
    }
    this.cx.fill();
  }
  removeDraw(x, y, draw) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = '#FFFFFF'
    this.cx.fill();
  }
  historyDraw(x, y) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = '#FF0000'
    this.cx.fill();
  }

  getSeatSchedule(date, period, seatid) {
    this.seatservice.getseatSearchadmin(date, period, seatid).subscribe(result => {
      if (this.seats) {
        this.seats.forEach(seat => {
          this.removeDraw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
        });
      }
      this.seats = result.data;
      this.seats.forEach(seat => {
        if (seat.seatTypeId == this.selectedHistory.seatTypeId) {
          this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
        }
        if (seat.seatId == this.selectedHistory.seatId) {
          this.historyDraw(seat.displayX * this.ratio, seat.displayY * this.ratio);
        }
      });
    });
  }

  cancel_id:any
  delete(del, id) {
    console.log(id)
    this.cancel_id = id
    this.modalService.open(del);
  }
  reset() {
    this.seatservice.resetseat(this.cancel_id).subscribe(result => {
      console.log(result);
      this.seatstatus()
    });
  }
 seatInfoForm: FormGroup;
  isSeatInfoReady = 0;
  showSeatInfo(history: any) {
    console.log(history);
    this.selectedHistory = history;
    this.seatInfoForm = this.formBuilder.group(history);
    this.isSeatInfoReady = 1;
    console.log(this.seatInfoForm);
    this.mode = 1;
    this.getSeatSchedule(history.scheduleDate, history.periodCode, history.seatScheduleId);
  }
  changeSeat() {
    console.log(this.seatInfoForm.getRawValue());
    this.seatservice.changereserveseat(this.seatInfoForm.getRawValue()).subscribe(result => {
      console.log(result);
      this.mode = 0;
      console.log(result.apiResponse.desc)

    });
  }
  cancelpage() {
    console.log('Cancel');
    this.mode = 0;
  }
  periods() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Period', data)
      .subscribe((res: any) => {
        this.perioddata = res.data
        console.log(res.data);
      })
  }
  seattypes() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatType', data)
      .subscribe((res: any) => {
        this.typedata = res.data
        console.log(res.data);
      })
  }
  timedata = [{
    id: 1, hour: ['1']
  },
  {
    id: 2, hour: ['2']
  },
  {
    id: 3, hour: ['3']
  }
  ];

  open(content) {
    this.modalService.open(content);
  }
  admin() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  reservingmanageseatschedule() {
    return this.router.navigate(['home/reservingmanageseatschedule'])
  }
  reservationsummary() {
    return this.router.navigate(['home/reservationsummary'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
}
