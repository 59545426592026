import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {SeatService} from '../seat.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../authen.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userName = '';
  isAdmin = false;
  @ViewChild('navBurger') navBurger: ElementRef;
  @ViewChild('navMenu') navMenu: ElementRef;

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private seatservice: SeatService,
              private authService: AuthService,
              private http: HttpClient) {
    const auth = localStorage.getItem('reserving');
    if (auth) authService = JSON.parse(auth);


    this.userName = authService.userName;
    this.isAdmin = authService.isAdmin;

  }

  ngOnInit() {

  }

  toggleNavbar() {
    // this.navBurger.nativeElement.classList.toggle('is-active');
    // this.navMenu.nativeElement.classList.toggle('is-active');
    // console.log('Toggle burger');
    // this.isBurgerActive = !this.isBurgerActive;
  }

  loginuser() {
    return this.router.navigate(['loginuser']);
  }
  logout() {
    localStorage.removeItem('reserving');
    this.router.navigate(['login']);
  }
}
