import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {AdminComponent} from './admin/admin.component';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ZonebComponent} from './admin/zoneb/zoneb.component';
import {ZonecComponent} from './admin/zonec/zonec.component';
import {ZonedComponent} from './admin/zoned/zoned.component';
import {ZoneeComponent} from './admin/zonee/zonee.component';
import {ZonefComponent} from './admin/zonef/zonef.component';
import {ZonegComponent} from './admin/zoneg/zoneg.component';
import {ZonepComponent} from './admin/zonep/zonep.component';
import {ZonesComponent} from './admin/zones/zones.component';
import {SetadminComponent} from './admin/setadmin/setadmin.component';
import {LoginuserComponent} from './admin/loginuser/loginuser.component';
import {LoginadminComponent} from './admin/loginadmin/loginadmin.component';
import {SeatService} from './seat.service';
import {DatePipe} from '@angular/common';
import {DeviceComponent} from './device/device.component';
import {ManageComponent} from './admin/manage/manage.component';
import {ZoneService} from './zone.service';
import {DeviceService} from './device.service';
import {ManagedeviceComponent} from './device/managedevice/managedevice.component';
import {CreatedeviceComponent} from './device/createdevice/createdevice.component';
import {MaccomComponent} from './device/maccom/maccom.component';
import {ScannerComponent} from './device/scanner/scanner.component';
import {EmptyComponent} from './device/empty/empty.component';
import {AdminmanageComponent} from './adminmanage/adminmanage.component';
import {CreateadminComponent} from './adminmanage/createadmin/createadmin.component';
import {AdminlistComponent} from './adminmanage/adminlist/adminlist.component';
import {ZonestatusComponent} from './admin/zonestatus/zonestatus.component';
import {SeatstatusComponent} from './admin/seatstatus/seatstatus.component';
import {EditadminComponent} from './adminmanage/editadmin/editadmin.component';
import {AdminService} from './admin.service';
import {ReservingadminmanageComponent} from './reservingadminmanage/reservingadminmanage.component';
import {LoginComponent} from './login/login.component';
import {AdminmanageseatComponent} from './reservingadminmanage/adminmanageseat/adminmanageseat.component';
import {ClosescheduleComponent} from './closeschedule/closeschedule.component';
import {CloseschedulemanageComponent} from './closeschedule/closeschedulemanage/closeschedulemanage.component';
// import { AuthService } from './authen.service';
import {NgbDateCustomParserFormatter} from './dateformat';
import {ReservationsummaryComponent} from './reservationsummary/reservationsummary.component';
import {ReservingmanageseatscheduleComponent} from './reservingmanageseatschedule/reservingmanageseatschedule.component';
import {ClosescheduleDoComponent} from './closeschedule/closeschedule-do/closeschedule-do.component';
import {NgSelectModule} from '@ng-select/ng-select';

// import { FlexLayoutModule } from '@angular/flex-layout'


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    ZonebComponent,
    ZonecComponent,
    ZonedComponent,
    ZoneeComponent,
    ZonefComponent,
    ZonegComponent,
    ZonepComponent,
    ZonesComponent,
    SetadminComponent,
    LoginuserComponent,
    LoginadminComponent,
    DeviceComponent,
    ManageComponent,
    ManagedeviceComponent,
    CreatedeviceComponent,
    MaccomComponent,
    ScannerComponent,
    EmptyComponent,
    AdminmanageComponent,
    CreateadminComponent,
    AdminlistComponent,
    ZonestatusComponent,
    SeatstatusComponent,
    EditadminComponent,
    ReservingadminmanageComponent,
    LoginComponent,
    AdminmanageseatComponent,
    ClosescheduleComponent,
    CloseschedulemanageComponent,
    ClosescheduleDoComponent,
    ReservationsummaryComponent,
    ReservingmanageseatscheduleComponent
    // EditseatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    NgSelectModule
    // FlexLayoutModule
  ],
  providers: [SeatService, DatePipe, ZoneService, DeviceService, AdminService, {
    provide: NgbDateParserFormatter,
    useClass: NgbDateCustomParserFormatter
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
