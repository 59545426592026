import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DeviceService } from 'src/app/device.service';
import { Device } from 'src/app/device.model';
import { AuthService } from 'src/app/authen.service';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent implements OnInit {
  typename:any
  device: Device[] = [];
  constructor(private router: Router,private modalService: NgbModal, private formBuilder: FormBuilder, private deviceservice: DeviceService, private http: HttpClient,
    private authenService:AuthService) {
      console.log(this.authenService.userName);
      // console.log(this.authenService.isAdmin);
  }
  changeScheduleStatuss(event) {
    console.log(event)
    this.typename = event.target.value;
  }
  gettype(typename) {
    this.deviceservice.search(typename).subscribe(result => {
      console.log(result);
      this.device = result.data;
      this.device = this.device.filter(x => x.deviceTypeName == 'Scanner')
    });
  }
  changeid(value) {
    console.log(value);
    this.typename = value;
    this.gettype(this.typename)
  }
  ngOnInit() {
    this.deviceservice.getalldevice().subscribe(result => {
      console.log(result);
      this.device = result.data;
      this.device = this.device.filter(x => x.deviceTypeName == 'Scanner')
    });
  }
  update(id) {
    console.log(id)
    this.router.navigate(['home/managedevice'])
    this.deviceservice.setData(id)
  }

  createdevice() {
    return this.router.navigate(['home/createdevice'])
  }

  remove(){
    // var dialog = confirm(devicere.seatName)
    // if(dialog == true){ 
      this.deviceservice.removeDevice(this.device_id).subscribe(result=>{
        console.log(result);
        // alert('Delete Data!')
        this.ngOnInit()
      });
    // }
  }
  windows() {
    return this.router.navigate(['home/device'])
  }
  mac() {
    return this.router.navigate(['home/maccom'])
  }
  scanner() {
    return this.router.navigate(['home/scanner'])
  }
  empty() {
    return this.router.navigate(['home/empty'])
  }
  admin() {
    return this.router.navigate(['home/admin'])
  }
  devicepage() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  device_id : any
  open(content,id) {
    this.device_id = id
    this.modalService.open(content);
  }
}
