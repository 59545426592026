import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/authen.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SeatService } from '../seat.service';
import { appInitializerFactory } from '@angular/platform-browser/src/browser/server-transition';
import { NgbPopoverWindow } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-reservationsummary',
  templateUrl: './reservationsummary.component.html',
  styleUrls: ['./reservationsummary.component.css']
})
export class ReservationsummaryComponent implements OnInit {

  constructor(private router: Router, private modalService: NgbModal, private http: HttpClient, private seatservice: SeatService, private formBuilder: FormBuilder, public datePipe: DatePipe,
    private authenService: AuthService) {
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  reservationsummarydata: any
  scheduleDateform: any
  minDate: any;
  formdate: any;
  todate: any;
  isFormReady = false;

  ngOnInit() {

  }

  admin() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  reservingmanageseatschedule() {
    return this.router.navigate(['home/reservingmanageseatschedule'])
  }
  reservationsummary() {
    return this.router.navigate(['home/reservationsummary'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }

  getFinancialData(fromDate: any, toDate: any) {
    var newDate1 = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    var newDate2 = this.datePipe.transform(toDate, 'yyyy-MM-dd');
    console.log(newDate1);
    // console.log(newDate2);
    this.seatservice.getsummary(newDate1, newDate2).subscribe(result => {
      console.log(result);
      this.reservationsummarydata = result.data
      this.isFormReady = true
    });
  }
}
