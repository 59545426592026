import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Zone } from 'src/app/zone.model';
import { ZoneService } from 'src/app/zone.service';
import { AuthService } from 'src/app/authen.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-createadmin',
  templateUrl: './createadmin.component.html',
  styleUrls: ['./createadmin.component.css']
})
export class CreateadminComponent implements OnInit {

  adminlist: any;
  registerform: FormGroup;
  submitted = false
  isAdmin: any;

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder, private zoneservice: ZoneService, private http: HttpClient,
    private authenService: AuthService) {
    // this.isAdmin = this.authenService.isAdmin;
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);
  }
  ngOnInit() {
    this.create()
  }
  create() {
    this.registerform = this.formBuilder.group({
      id: '',
      userName: ['', Validators.required],
      password: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      isAdmin: false
    })
  }
  get f() { return this.registerform.controls; }
  news() {
    this.submitted = true;
    {
      if (this.registerform.invalid) {
        return;
      }
      var data = {
        'apiRequest': { 'action': 'new' }
        , 'data': [this.registerform.value]
      }
      this.http.post(environment.apiUrl + '/api/core/User', data)
        .subscribe((res: any) => {
          this.router.navigate(['home/adminmanage'])
          console.log(res.data);
          // alert('success')
        })
    }
  }
  admin() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }

  open(content) {
    this.modalService.open(content);
  }
}
