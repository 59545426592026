import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { fromEvent } from 'rxjs';
import { Seat } from 'src/app/seat.model';
import { SeatService } from 'src/app/seat.service';

@Component({
  selector: 'app-adminmanageseat',
  templateUrl: './adminmanageseat.component.html',
  styleUrls: ['./adminmanageseat.component.css']
})
export class AdminmanageseatComponent implements OnInit {
  // seats: Seat[]
  // reserving: FormGroup
  // histories: any;
  // seatdata: any
  // typedata: any
  // perioddata: any;
  // manageseat: any;
  // isFormReady = false;

  constructor(private router: Router, private http: HttpClient, private seatservice: SeatService, private formBuilder: FormBuilder, public datePipe: DatePipe) {
    // this.seatservice.getseatchange().subscribe(result => {
    //   console.log(result)
    //   this.seats = result.data;
    //   this.reserving = this.formBuilder.group(result.data[0]);

    //   console.log(this.reserving);
    //   this.reserving.patchValue(result.data[0]);
    //   console.log(this.reserving);

    //   this.manageseat = this.seats.find(f => f.id == this.seatservice.getData())
    //   console.log(this.manageseat)
    //   this.isFormReady = true
    // });
  }

  // @ViewChild('canvas') canvas: ElementRef;
  // cx: CanvasRenderingContext2D;
  // canvasEl: HTMLCanvasElement;
  // ratio: number;
  //  seats: any;

  // @HostListener('window:resize', ['$event'])
  // onResize(event?) {
  //   this.canvasEl.height = window.innerHeight;
  //   this.canvasEl.width = window.innerWidth;
  //   this.ratio = window.innerHeight / 1000;
  //   console.log(this.ratio);
  // }

  ngOnInit() {
    // this.canvasEl = this.canvas.nativeElement;
    // this.cx = this.canvasEl.getContext('2d');

    // fromEvent(this.canvasEl, 'mousedown').subscribe(location => {
    //   this.seats.forEach(element => {

    //     if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
    //       (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
    //       console.log(element);
    //       if (element.scheduleStatusId == '00000000-0000-0000-0000-000000000000') {
    //         this.reserving.patchValue({
    //           id: element.id,
    //           seatId: element.seatId,
    //           seatCode: element.seatCode,
    //           seatName: element.seatName,
    //           scheduleDate: element.scheduleDate,
    //           periodId: element.periodId,
    //           periodCode: element.periodCode,
    //           periodName: element.periodName,
    //           availablePeriod: element.availablePeriod,
    //           scheduleStatusId: element.scheduleStatusId,
    //           scheduleStatusCode: element.scheduleStatusCode,
    //           scheduleStatusName: element.scheduleStatusName,
    //           displayX: element.displayX,
    //           displayY: element.displayY
    //           // userName: element.userName
    //         })
    //       }
    //     }
    //   });
    // }
    // );
    // this.onResize();
    // this.periods();
    // this.seatlist();

  }

  // getSeatSchedule(date, period) {
  //   this.seatservice.getseatSearch(date, period).subscribe(result => {
  //     console.log(result)
  //     this.seats = result.data;
  //     this.seats.forEach(seat => {
  //       console.log(seat)
  //       this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
  //     });
  //   });
  // }

  // draw(x, y, draw) {
  //   this.cx.beginPath();
  //   this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
  //   switch (draw.scheduleStatusId) {
  //     case '10000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'red'
  //       break;

  //     case '20000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'gray'
  //       break;

  //     case '00000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'green'
  //       break;
  //   }
  //   this.cx.fill();
  // }

  // timedata = [{
  //   id: 1, hour: ['1']
  // },
  // {
  //   id: 2, hour: ['2']
  // },
  // {
  //   id: 3, hour: ['3']
  // }
  // ];

  // periods() {
  //   var data = {
  //     'apiRequest': { 'action': 'list' }
  //   }
  //   this.http.post('http://roomcontrol.ctt-center.com/api/core/Period', data)
  //     .subscribe((res: any) => {
  //       this.perioddata = res.data
  //       console.log(res.data);
  //     })
  // }
  // seatlist() {
  //   var data = {
  //     'apiRequest': { 'action': 'list' }
  //   }
  //   this.http.post('http://roomcontrol.ctt-center.com/api/core/Seat', data)
  //     .subscribe((res: any) => {
  //       this.seatdata = res.data
  //       console.log(res.data);
  //     })
  // }

}
