import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SeatService } from '../seat.service';
import { Seat } from '../seat.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, interval } from 'rxjs';
import { AuthService } from '../authen.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-reservingadminmanage',
  templateUrl: './reservingadminmanage.component.html',
  styleUrls: ['./reservingadminmanage.component.css']
})
export class ReservingadminmanageComponent implements OnInit {

  mode = 0;
  histories: any;
  lastSelectedSeat: any;
  selectedHistory: any;
  seatdata: any
  typedata: any
  perioddata: any;
  isFormReady = false;
  reload = true;
  seletedDate: any;
  minDate: any;
  scheduleDateform: any
  seatstatusdata: any;
  scheduleStatusId: any;
  searching: any;
  currentCursor = "default";
  currentSeat = '00000000-0000-0000-0000-000000000000';

  changeScheduleStatuss(event) {
    console.log(event)
    this.scheduleDateform = event.target.value;
  }

  formscheduleDate() {
    this.scheduleDateform = this.formBuilder.group({
      scheduleDate: [this.minDate],
      scheduleStatusId: ''
    });
  }
  isAdmin: any;
  constructor(private router: Router, private http: HttpClient, private modalService: NgbModal, private seatservice: SeatService, private formBuilder: FormBuilder, public datePipe: DatePipe,
    private authenService: AuthService) {
    // this.isAdmin = this.authenService.isAdmin;
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);

    this.seatInfoForm = formBuilder.group({});
    this.isFormReady = true;

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    console.log(event);
    let myDate = current;
    var newDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
    console.log(newDate);
    this.seletedDate = newDate;
    this.getFinancialData(newDate, this.scheduleStatusId, this.searching);
  }
  changeFinanceDate(event: any) {
    console.log(event);
    let myDate = new Date(event.year, event.month - 1, event.day);
    var newDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
    console.log(newDate);
    this.seletedDate = newDate;
    this.getFinancialData(newDate, this.scheduleStatusId, this.searching);
  }
  changeScheduleStatus(value) {
    console.log(value);
    this.scheduleStatusId = value;
    this.getFinancialData(this.seletedDate, this.scheduleStatusId, this.searching);
  }
  search(value) {
    console.log(value);
    this.searching = value;
    this.getFinancialData(this.seletedDate, this.scheduleStatusId, this.searching);
  }

  // getstatuss(){
  // let myDate = new Date(event.year, event.month - 1, event.day);
  // var newDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
  // console.log(newDate);
  // this.getFinancialData(this.seletedDate,this.scheduleStatusId)
  // }

  @ViewChild("divCanvas") divCanvas: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  cx: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;
  ratio: number;
  seats: any;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    console.log(window.innerWidth);
    console.log(this.divCanvas.nativeElement.offsetWidth);
    console.log(this.divCanvas.nativeElement.offsetHeight);

    //if (this.firstLoad) {
    var screen_ratio = 2;
    if (window.innerWidth < 768) {
      screen_ratio = 1;
    }
    else {
      screen_ratio = 2;
    }
    const size = window.innerHeight < window.innerWidth / screen_ratio ? window.innerHeight : window.innerWidth / screen_ratio;
    if (window.innerHeight < (window.innerWidth / screen_ratio)) {

      this.canvasEl.height = size; // window.innerHeight;
      this.canvasEl.width = size; // window.innerWidth/screen_ratio;
      this.ratio = window.innerHeight / 1000;
    }
    else {
      this.canvasEl.height = size;// window.innerHeight;
      this.canvasEl.width = size;// window.innerWidth/screen_ratio;
      this.ratio = (window.innerWidth / screen_ratio) / 1000;
    }

    //console.log(this.divCanvas.nativeElement.offsetWidth);
    if (this.seats) {
      this.seats.forEach(seat => {
        this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
      });
    }
    if (this.lastSelectedSeat) {
      console.log(this.lastSelectedSeat);
      //this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
      this.selectedDraw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio);
    }

    // this.ngAfterViewInit()
    console.log(this.ratio);
    // this.can
    // console.log(window.innerWidth);
    // this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');
    fromEvent(this.canvasEl, 'mousemove').subscribe(location => {
      // console.log("Moves");
      //console.log(this.ratio);
      var match = false;
      this.seats.forEach(element => {

        //console.log(element);
        if (!match) {
          if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
            (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
            if (this.currentSeat == element.id) {
              match = true;
            }
            else {
              this.currentSeat = element.id;
              if (this.currentCursor != 'pointer') {
                console.log(this.currentCursor);
                this.canvasEl.style.cursor = 'pointer';
                this.currentCursor = 'pointer';
                match = true;
                console.log('Change cursor to point');
              }
              else {
                console.log('Already pointer');
              }
            }

          }
          else {


          }
        }


      });
      if (!match) {
        if (this.currentCursor != 'default') {
          this.canvasEl.style.cursor = 'default';
          this.currentCursor = 'default';
          this.currentSeat = '00000000-0000-0000-0000-000000000000';
        }
      }

    }
    );
    fromEvent(this.canvasEl, 'mousedown').subscribe(location => {
      this.seats.forEach(element => {

        if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
          (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
          console.log(element);
          if (element.scheduleStatusId == '00000000-0000-0000-0000-000000000000') {
            this.seatInfoForm.patchValue({
              id: element.id,
              seatId: element.seatId,
              seatCode: element.seatCode,
              seatName: element.seatName,
              // scheduleDate: element.scheduleDate,
              // periodId: element.periodId,
              // periodCode: element.periodCode,
              // periodName: element.periodName,
              // availablePeriod: element.availablePeriod,
              scheduleStatusId: element.scheduleStatusId,
              scheduleStatusCode: element.scheduleStatusCode,
              scheduleStatusName: element.scheduleStatusName,
              displayX: element.displayX,
              displayY: element.displayY,
              // userName: element.userName
            });
            if (this.lastSelectedSeat) {
              this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
            }
            this.selectedDraw(element.displayX * this.ratio, element.displayY * this.ratio);
            this.lastSelectedSeat = element;
          }
        }
      });
    }
    );
    this.onResize();
    this.periods();
    this.formscheduleDate();
    this.seattypes();
    this.seatstatus();
  }
  selectedDraw(x, y) {
    console.log(x);
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = 'yellow';
    this.cx.fill();
  }

  draw(x, y, draw) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    switch (draw.scheduleStatusId) {
      case '10000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'red';
        break;
      case '00000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = '#4fcc25';
        break;
      case '20000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'blue';
        break;
      case '30000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'gray';
        break;
    }
    this.cx.fill();
  }
  removeDraw(x, y, draw) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = '#FFFFFF'
    this.cx.fill();
  }
  historyDraw(x, y) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = '#FF0000'
    this.cx.fill();
  }
  getSeatSchedule(date, period, seatid) {
    this.seatservice.getseatSearchadmin(date, period, seatid).subscribe(result => {
      if (this.seats) {
        this.seats.forEach(seat => {
          this.removeDraw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
        });
      }
      this.seats = result.data;
      this.seats.forEach(seat => {
        if (seat.seatTypeId == this.selectedHistory.seatTypeId) {
          this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
        }
        if (seat.seatId == this.selectedHistory.seatId) {
          this.historyDraw(seat.displayX * this.ratio, seat.displayY * this.ratio);
        }
      });
    });
  }
  seatInfoForm: FormGroup;
  isSeatInfoReady = 0;
  showSeatInfo(history: any) {
    console.log(history);
    this.selectedHistory = history;
    this.seatInfoForm = this.formBuilder.group(history);
    this.isSeatInfoReady = 1;
    console.log(this.seatInfoForm);
    this.mode = 1;
    this.getSeatSchedule(history.scheduleDate, history.periodCode, history.seatScheduleId);
  }
  changeSeat() {
    console.log(this.seatInfoForm.getRawValue());
    this.seatservice.changereserveseat(this.seatInfoForm.getRawValue()).subscribe(result => {
      console.log(result);
      this.mode = 0;
      this.getFinancialData(this.seletedDate, this.scheduleStatusId, this.searching);
      console.log(result.apiResponse.desc)
    });
  }
  getFinancialData(scheduleDates, scheduleStatusId, searching) {
    console.log('Change Date');
    this.seatservice.getseat(scheduleDates, scheduleStatusId, searching).subscribe(result => {
      console.log(result);
      this.mode = 0;
      this.seats = result.data;
    });
  }
  admin() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  reservingmanageseatschedule() {
    return this.router.navigate(['home/reservingmanageseatschedule'])
  }
  reservationsummary() {
    return this.router.navigate(['home/reservationsummary'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  cancelpage() {
    console.log('Cancel');
    this.mode = 0;
    this.getFinancialData(this.seletedDate, this.scheduleStatusId, this.searching);
  }
  periods() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Period', data)
      .subscribe((res: any) => {
        this.perioddata = res.data
        console.log(res.data);
      })
  }
  seattypes() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatType', data)
      .subscribe((res: any) => {
        this.typedata = res.data
        console.log(res.data);
      })
  }
  timedata = [{
    id: 1, hour: ['1']
  },
  {
    id: 2, hour: ['2']
  },
  {
    id: 3, hour: ['3']
  }
  ];

  seatstatus() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/ScheduleStatus', data)
      .subscribe((res: any) => {
        this.seatstatusdata = res.data
        console.log(res.data);
      })
  }
  open(content) {
    this.modalService.open(content);
  }
  delete(del, id) {
    console.log(id)
    this.cancel_id = id
    this.modalService.open(del);
  }
  cancel_id: any
  cancel() {
    this.seatservice.cancleseat(this.cancel_id).subscribe(result => {
      console.log(result);
      this.getFinancialData(this.seletedDate, this.scheduleStatusId, this.searching);
    });
  }
  resetseat(reset, id) {
    console.log(id)
    this.reset_id = id
    this.modalService.open(reset);
  }
  reset_id: any
  reset() {
    this.seatservice.resetseat(this.reset_id).subscribe(result => {
      console.log(result);
      this.getFinancialData(this.seletedDate, this.scheduleStatusId, this.searching);
    });
  }
  seatlist() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatSchedule', data)
      .subscribe((res: any) => {
        this.seatdata = res.data
        console.log(res.data);
      })
  }
}
