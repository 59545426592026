import { Component, ViewChild, ElementRef, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SeatService } from 'src/app/seat.service';
import { fromEvent } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/alert.service';
import {environment} from '../../../environments/environment';
// import { deflateRaw } from 'zlib';



@Component({
  selector: 'app-loginadmin',
  templateUrl: './loginadmin.component.html',
  styleUrls: ['./loginadmin.component.css']
})
export class LoginadminComponent implements OnInit {
  reserving: FormGroup
  perioddata: any
  seatdata: any
  typedata: any
  isFormReady = false;
  get today() {
    return new Date();
  }

  @ViewChild('canvas') canvas: ElementRef;
  cx: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;
  ratio: number;
  seats: any;
  loading = false;


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.canvasEl.height = window.innerHeight;
    this.canvasEl.width = window.innerWidth;
    this.ratio = window.innerHeight / 1000;

    // this.ngAfterViewInit()
    console.log(this.ratio);
    // this.can
    // console.log(window.innerWidth);
    // this.innerWidth = window.innerWidth;
  }

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder,
    private seatservice: SeatService,
    public datePipe: DatePipe,
    private http: HttpClient,
    private alertService: AlertService) {
    this.seatservice.getallseat().subscribe(result => {
      console.log(result);
      this.reserving = result.data;
      var date = this.datePipe.transform(new Date(), 'y-MM-dd')
      const newreserving = {
        id: '',
        seatId: '',
        seatCode: '',
        seatName: '',
        scheduleDate: date,
        periodId: '',
        periodCode: '',
        periodName: '',
        availablePeriod: '3',
        scheduleStatusId: '',
        scheduleStatusCode: '',
        scheduleStatusName: '',
        displayX: '',
        displayY: '',
        userName: '',
        password: ''
      }
      this.reserving = this.formBuilder.group(newreserving);
      this.isFormReady = true;
      console.log(this.reserving);
    });

  }

  ngOnInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');

    fromEvent(this.canvasEl, 'mousedown').subscribe(location => {
      //console.log(location);
      //console.log(this.ratio);
      this.seats.forEach(element => {
        //console.log(element);

        if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
          (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
          console.log(element);
          if (element.scheduleStatusId == '00000000-0000-0000-0000-000000000000') {
            this.reserving.patchValue({
              id: element.id,
              seatId: element.seatId,
              seatCode: element.seatCode,
              seatName: element.seatName,
              periodId: element.periodId,
              periodCode: element.periodCode,
              periodName: element.periodName,
              availablePeriod: element.availablePeriod,
              scheduleStatusId: element.scheduleStatusId,
              scheduleStatusCode: element.scheduleStatusCode,
              scheduleStatusName: element.scheduleStatusName,
              displayX: element.displayX,
              displayY: element.displayY
            })
          }
        }
      });
    }
    );
    this.onResize();

    this.periods()
    this.seattypes()
    this.seat()
  }
  // ngAfterViewInit() {

  //   console.log(this.datePipe.transform(new Date(), 'y-MM-dd'))
  //   var date = this.datePipe.transform(new Date(), 'y-MM-dd')

  //   this.seatservice.getseat(date).subscribe(result => {

  //     this.seats = result.data;
  //     this.seats.forEach(seat => {

  //       this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio);
  //     });

  //   });
  // }
  draw(x, y, draw) {


    // this.cx.fillStyle = '#000000';
    // this.cx.
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    switch (draw.scheduleStatusId) {
      case '10000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'red'
        break;

      case '20000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'gray'
        break;

      case '00000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'green'
        break;
    }




    // if(draw.scheduleStatusId == '10000000-0000-0000-0000-000000000000'){
    // this.cx.fillStyle = 'red';
    // } else {
    // this.cx.fillStyle = 'green';


    // this.cx.fillStyle = 'green';
    this.cx.fill();
  }
  timedata = [{
    id: 1, hour: ['1']
  },
  {
    id: 2, hour: ['2']
  },
  {
    id: 3, hour: ['3']
  }
  ];

  periods() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Period', data)
      .subscribe((res: any) => {
        this.perioddata = res.data
        console.log(res.data);
      })
  }

  seattypes() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatType', data)
      .subscribe((res: any) => {
        this.typedata = res.data
        console.log(res.data);
      })
  }
  seat() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatdata = res.data
        console.log(res.data);
      })
  }

  // popup
  open(content) {
    this.modalService.open(content, { size: 'lg' });
  }


  // navigate
  admin() {
    return this.router.navigate(['admin'])
  }
  setadmin() {
    return this.router.navigate(['setadmin'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  loginadmin() {
    return this.router.navigate(['loginadmin'])
  }


  // seard
  // seard(date, period, time, type) {
  //   if (type == '') {
  //     var temtype = this.typedata.find(x => x.id == type)
  //     var typeP: any = temtype
  //   }
  //   if (period != '') {
  //     var temPeriod = this.perioddata.find(x => x.id == period)
  //     var codeP: any = temPeriod.code
  //   } else {
  //     var codeP: any = ''
  //   }
  //   if (time != '') {
  //     var temtime = this.timedata.find(x => x.id == time)
  //     var timeP: any = temtime
  //   } else {
  //     var timeP: any = ''
  //   }

  //   console.log(date, typeP, codeP, timeP)
  //   this.seatservice.getseatSearch(date, codeP).subscribe(result => {
  //     console.log(result)
  //     this.seats = result.data;
  //     this.seats.forEach(seat => {
  //       console.log(seat)
  //       this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
  //     });
  //   });
  // }

  // save

  reserve() {
    console.log(this.reserving.value);
    // console.log(this.reserving.get('data').value)
    this.seatservice.reserveseat(this.reserving.value).subscribe(data => {
      alert(data.apiResponse.desc)
      console.log(data);
    });
  }
}
