import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SeatService} from 'src/app/seat.service';
import {fromEvent, interval} from 'rxjs';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-loginuser',
  templateUrl: './loginuser.component.html',
  styleUrls: ['./loginuser.component.css']
})
export class LoginuserComponent implements OnInit {
  reserving: FormGroup;
  perioddata: any;
  seatdata: any;
  typedata: any;
  lastSelectedSeat: any;
  isFormReady = false;
  message: any;
  submessage: any;
  error: any;
  mode = 0; //0 List,1-Edit
  minDate: any;
  defaultdata: any;
  currentCursor = 'default';
  currentSeat = '00000000-0000-0000-0000-000000000000';

  endpoint: string = '';
  @ViewChild('divCanvas') divCanvas: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  cx: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;
  ratio: number;
  seats: any;
  submitted = false;
  firstLoad = true;
  timedata = [{
    id: 1, hour: ['1']
  },
    {
      id: 2, hour: ['2']
    },
    {
      id: 3, hour: ['3']
    }
  ];
  //width: number;
  //height: number;
  private refreshInterval = interval(10000);
  private refreshInterval$ = interval(1500);

  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder,
              private seatservice: SeatService,
              public datePipe: DatePipe,
              private http: HttpClient
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    // this.seatservice.getallseat().subscribe(result => {
    //   console.log(result);
    //   this.reserving = result.data;

    //   // config.outsideDays = 'hidden';
    //   // config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 7;

    //   // this.isFormReady = true;
    //   // console.log(this.reserving);
    // });

    const params = {apiRequest: {action: 'get'}, data: [{scheduleDate: new Date('dd/mm/yyyy')}]};
    this.http.post(environment.apiUrl + '/api/core/period', params).subscribe((response: any) => {
      console.log(response.data);
      this.perioddata = response.data;
    });
    const param = {apiRequest: {action: 'list'}};
    this.http.post(environment.apiUrl + '/api/core/SeatType', param).subscribe((res: any) => {
      this.typedata = res.data;
    });
  }

  get today() {
    return new Date(), 'dd-MM-yy';
  }

  get f() {
    return this.reserving.controls;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    console.log(window.innerWidth);
    console.log(this.divCanvas.nativeElement.offsetWidth);
    console.log(this.divCanvas.nativeElement.offsetHeight);

    //if (this.firstLoad) {
    var screen_ratio = 2;
    if (window.innerWidth < 768) {
      screen_ratio = 1;
    } else {
      screen_ratio = 2;
    }
    const size = window.innerHeight < window.innerWidth / screen_ratio ? window.innerHeight : window.innerWidth / screen_ratio;
    if (window.innerHeight < (window.innerWidth / screen_ratio)) {

      this.canvasEl.height = size; // window.innerHeight;
      this.canvasEl.width = size; // window.innerWidth/screen_ratio;
      this.ratio = window.innerHeight / 1000;
    } else {
      this.canvasEl.height = size;// window.innerHeight;
      this.canvasEl.width = size;// window.innerWidth/screen_ratio;
      this.ratio = (window.innerWidth / screen_ratio) / 1000;
    }


    //   this.firstLoad = false;
    // }
    // else {
    //   if (this.divCanvas.nativeElement.offsetWidth >= this.divCanvas.nativeElement.offsetHeight) {
    //     this.canvasEl.height = this.divCanvas.nativeElement.offsetHeight;
    //     this.canvasEl.width = this.divCanvas.nativeElement.offsetHeight;
    //     this.ratio = this.divCanvas.nativeElement.offsetHeight / 1000;
    //     console.log('height');
    //   }
    //   else {
    //     this.canvasEl.height = this.divCanvas.nativeElement.offsetWidth;
    //     this.canvasEl.width = this.divCanvas.nativeElement.offsetWidth;
    //     this.ratio = this.divCanvas.nativeElement.offsetWidth/ 1000;
    //     console.log('width');
    //   }
    //}


    //console.log(this.divCanvas.nativeElement.offsetWidth);
    if (this.seats) {
      this.seats.forEach(seat => {
        this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
      });
    }
    if (this.lastSelectedSeat) {
      console.log(this.lastSelectedSeat);
      //this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
      this.selectedDraw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio);
    }

    // this.ngAfterViewInit()
    console.log(this.ratio);
    // this.can
    // console.log(window.innerWidth);
    // this.innerWidth = window.innerWidth;
  }

  getSeatSchedule() {
    var data = this.reserving.value;
    console.log(data.scheduleDate);
    console.log(data.scheduleDate.year);
    console.log(data.scheduleDate.month);
    console.log(data.scheduleDate.day);
    let myDate = new Date(data.scheduleDate.year, data.scheduleDate.month - 1, data.scheduleDate.day);
    console.log(myDate);
    data.scheduleDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');

    var params = {apiRequest: {action: 'get'}, data: [this.reserving.value]};

    this.http.post(environment.apiUrl + '/api/core/seatschedule', params).subscribe((response: any) => {
      this.isFormReady = false;
      if (this.seats) {
        this.seats.forEach(seat => {
          this.removeDraw(seat.displayX * this.ratio, seat.displayY * this.ratio);
        });
      }
      this.seats = response.data;
      this.seats.forEach(seat => {
        this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
      });
      this.isFormReady = true;
    });
  }

  confirm() {
    this.mode = 1;
  }

  refseat() {
    this.refreshInterval.subscribe(() => {

      }
    );
  }

  // drawRectangle(x, y, draw) {
  //   switch (draw.scheduleStatusId) {
  //     case '10000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'red'
  //       break;
  //     case '00000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = '#4fcc25'
  //       break;
  //     case '20000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'blue'
  //       break;
  //     case '30000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'gray'
  //       break;
  //   }
  //   this.cx.fillRect(x - 6, y - 9, 22 * this.ratio, 22 * this.ratio);
  // }
  // drawtriangle(x, y, draw) {
  //   switch (draw.scheduleStatusId) {
  //     case '10000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'red'
  //       break;
  //     case '00000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = '#4fcc25'
  //       break;
  //     case '20000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'blue'
  //       break;
  //     case '30000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'gray'
  //       break;
  //   }
  //   this.cx.moveTo(x - (10 * this.ratio), y + (10 * this.ratio));
  //   this.cx.lineTo(x, y - (10 * this.ratio));
  //   this.cx.lineTo(x + (10 * this.ratio), y + (10 * this.ratio));
  //   this.cx.fill()
  // }
  // drawtriangledrown(x, y, draw) {
  //   switch (draw.scheduleStatusId) {
  //     case '10000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'red'
  //       break;
  //     case '00000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = '#4fcc25'
  //       break;
  //     case '20000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'blue'
  //       break;
  //     case '30000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'gray'
  //       break;
  //   }
  //   this.cx.moveTo(x + (10 * this.ratio), y - (10 * this.ratio));
  //   this.cx.lineTo(x, y + (10 * this.ratio));
  //   this.cx.lineTo(x - (10 * this.ratio), y - (10 * this.ratio));
  //   this.cx.fill()
  // }
  // drawCircle(x, y, draw) {
  //   this.cx.beginPath();
  //   this.cx.arc(x, y, 9 * this.ratio, 0, 2 * Math.PI);

  //   switch (draw.scheduleStatusId) {
  //     case '10000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'red'
  //       break;
  //     case '00000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = '#4fcc25'
  //       break;
  //     case '20000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'blue'
  //       break;
  //     case '30000000-0000-0000-0000-000000000000':
  //       this.cx.fillStyle = 'gray'
  //       break;
  //   }
  //   this.cx.fill();

  ngOnInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');
    var params = {apiRequest: {action: 'get_default'}};
    var data = {
      'apiRequest': {'action': 'get_default'}
    };
    this.http.post(environment.apiUrl + '/api/core/seatschedule', data)
      .subscribe((res: any) => {
        this.defaultdata = res.data;
        console.log(res.data);
        if (res.data != 0) {

          this.http.post(environment.apiUrl + '/api/core/seatschedule', params).subscribe((response: any) => {
            this.seats = response.data;
            const params = {apiRequest: {action: 'get'}, data: [{scheduleDate: new Date('dd/mm/yyyy'),}]};
            this.http.post(environment.apiUrl + '/api/core/period', params).subscribe((response: any) => {
              this.perioddata = response.data;
              // const params = { apiRequest: { action: "list" } }
              // this.http.post("/api/core/SeatType", params).subscribe((res: any) => {
              //   this.typedata = res.data;


              this.seats.forEach(seat => {
                this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
                const newreserving = {
                  id: '',
                  seatId: '',
                  seatCode: '',
                  seatName: '',
                  scheduleDate: [this.minDate],
                  periodId: this.seats[0].periodId,
                  seatTypeId: '00000000-0000-0000-0000-000000000000',
                  periodCode: '',
                  periodName: '',
                  slots: '1',
                  scheduleStatusId: '',
                  scheduleStatusCode: '',
                  scheduleStatusName: '',
                  displayX: '',
                  displayY: '',
                  userName: ['', Validators.required],
                  password: ['', Validators.required]
                };
                this.reserving = this.formBuilder.group(newreserving);
                this.isFormReady = true;
              });
            });
            // });
          });
        } else {

          const newreserving = {
            id: '',
            seatId: '',
            seatCode: '',
            seatName: '',
            scheduleDate: [this.minDate],
            periodId: '',
            seatTypeId: '00000000-0000-0000-0000-000000000000',
            periodCode: '',
            periodName: '',
            slots: '1',
            scheduleStatusId: '',
            scheduleStatusCode: '',
            scheduleStatusName: '',
            displayX: '',
            displayY: '',
            userName: ['', Validators.required],
            password: ['', Validators.required]
          };
          this.reserving = this.formBuilder.group(newreserving);
          this.isFormReady = true;
        }
      });


    fromEvent(this.canvasEl, 'mousemove').pipe().subscribe(location => {
        // console.log("Moves");
        //console.log(this.ratio);
        var match = false;
        // for debugger
        // this.cx.beginPath();
        // this.cx.arc((location as MouseEvent).offsetX, (location as MouseEvent).offsetY, 7 * this.ratio, 0, 2 * Math.PI);
        // this.cx.fillStyle = "red"
        // this.cx.fill();

        // for debugger

        if (this.seats) {
          this.seats.forEach(element => {


            if (!match) {
              if ((location as MouseEvent).offsetX / this.ratio >= element.displayX - 5 && (location as MouseEvent).offsetX / this.ratio <= element.displayX + 5 &&
                (location as MouseEvent).offsetY / this.ratio >= element.displayY - 5 && (location as MouseEvent).offsetY / this.ratio <= element.displayY + 5) {
                if (this.currentSeat == element.id) {
                  match = true;
                } else {
                  this.currentSeat = element.id;
                  if (this.currentCursor != 'pointer') {
                    console.log(this.currentCursor);
                    this.canvasEl.style.cursor = 'pointer';
                    this.currentCursor = 'pointer';
                    match = true;
                    console.log('Change cursor to point');
                  } else {
                    console.log('Already pointer');
                  }
                }

              } else {


              }
            }


          });
          if (!match) {
            if (this.currentCursor != 'default') {
              this.canvasEl.style.cursor = 'default';
              this.currentCursor = 'default';
              this.currentSeat = '00000000-0000-0000-0000-000000000000';
            }
          }
        }
      }
    );

    fromEvent(this.canvasEl, 'mousedown').subscribe(location => {
        //console.log(location);
        //console.log(this.ratio);
        this.seats.forEach(element => {

          //console.log(element);

          if ((location as MouseEvent).offsetX / this.ratio >= element.displayX - 5 && (location as MouseEvent).offsetX / this.ratio <= element.displayX + 5 &&
            (location as MouseEvent).offsetY / this.ratio >= element.displayY - 5 && (location as MouseEvent).offsetY / this.ratio <= element.displayY + 5) {
            // console.log(element);
            if (element.scheduleStatusId == '00000000-0000-0000-0000-000000000000') {
              this.reserving.patchValue({
                id: element.id,
                seatId: element.seatId,
                seatCode: element.seatCode,
                seatName: element.seatName,
                scheduleStatusId: element.scheduleStatusId,
                scheduleStatusCode: element.scheduleStatusCode,
                scheduleStatusName: element.scheduleStatusName,
                displayX: element.displayX,
                displayY: element.displayY,
                seatTypeId: element.seatTypeId
              });
              if (this.lastSelectedSeat) {
                this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
              }
              this.selectedDraw(element.displayX * this.ratio, element.displayY * this.ratio);
              this.lastSelectedSeat = element;
            }
          }

        });

      }
    );
    this.onResize();

    // this.refreshInterval$.subscribe(() => {
    //   window.location.reload();
    //   // this.getSeatSchedule()
    //   console.log('ss')
    //           }
    //       );
  }

  selectedDraw(x, y) {
    // this.cx.fillStyle = '#000000';
    // this.cx.
    // console.log(x);
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);

    this.cx.fillStyle = 'yellow';
    // if(draw.seatStatusId == '10000000-0000-0000-0000-000000000000'){
    // this.cx.fillStyle = 'gray';
    // } else {}
    // this.cx.fillStyle = 'green';
    // this.cx.fillStyle = 'green';
    this.cx.fill();
  }

  draw(x, y, draw) {
    // this.cx.fillStyle = '#000000';
    // this.cx.
    //this.cx.beginPath();
    //this.cx.strokeRect(x, y, 5 * this.ratio, 10);

    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    switch (draw.scheduleStatusId) {
      case '10000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'red';
        break;
      case '00000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = '#4fcc25';
        break;
      case '20000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'blue';
        break;
      case '30000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'gray';
        break;
    }
    // this.cx.fillRect(x, y, 7 * this.ratio, 7 * this.ratio);

    // if(draw.seatStatusId == '10000000-0000-0000-0000-000000000000'){
    // this.cx.fillStyle = 'gray';
    // } else {}
    // this.cx.fillStyle = 'green';
    // this.cx.fillStyle = 'green';
    this.cx.fill();
  }

  // }
  removeDraw(x, y) {
    // this.cx.fillStyle = '#000000';
    // this.cx.
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = 'white';

    this.cx.fill();
  }

  seat() {
    var data = {
      'apiRequest': {'action': 'list'}
    };
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatdata = res.data;
        // console.log(res.data);
      });
  }

  // popup
  open(content) {
    this.modalService.open(content);
  }

  // navigate
  admin() {
    return this.router.navigate(['admin']);
  }

  setadmin() {
    return this.router.navigate(['setadmin']);
  }

  loginuser() {
    window.location.reload();
  }

  loginadmin() {
    return this.router.navigate(['loginadmin']);
  }

  // getDefaultSeatSchedule() {
  //   const params = { apiRequest: { action: "get_default" },   }
  //   this.http.post("/api/core/seatschedule", params).subscribe(response => {
  //     // console.log(response.data);
  //     this.perioddata = response.data;
  //   });
  // }
  // seard
  // seard(date, period) {

  //   if (period != '') {
  //     var temPeriod = this.perioddata.find(x => x.id == period)
  //     var codeP: any = temPeriod.code
  //   } else {
  //     var codeP: any = ''
  //   }

  //   console.log(date, codeP)
  //   this.seatservice.getseatSearch(date, codeP).subscribe(result => {
  //     console.log(result)
  //     this.seats = result.data;
  //     this.seats.forEach(seat => {
  //       console.log(seat)
  //       this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
  //     });
  //   });
  // }

  // saverowOrder

  login() {
    return this.router.navigate(['login']);
  }

  getPeriodForDate(date, slot, period) {

    // console.log(date);
    //var dateString = "23/10/2015"; // Oct 23

    var dateParts = date.split('/');
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    var newDate = this.datePipe.transform(dateObject, 'yyyy-MM-dd');
    // console.log(newDate);
    const params = {apiRequest: {action: 'get'}, data: [{scheduleDate: newDate, slots: slot, periodId: period}]};
    this.http.post(environment.apiUrl + '/api/core/period', params).subscribe((response: any) => {
      console.log(response);
      this.perioddata = response.data;
      // console.log(response.data);
      // this.perioddata = response.data;
    });

    // const paramtype = { apiRequest: { action: "get" }, data: [{ scheduleDate: date }] }
    // this.http.post("/api/core/SeatType", paramtype).subscribe(response => {
    //   // console.log(response.data);
    //   this.typedata = response.data;
    // });
    // this.seatservice.get().subscribe(result => {
    //   console.log(result)
    //   this.seats = result.data;
    //   this.seats.forEach(seat => {
    //     console.log(seat)
    //     this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
    //   });
    // });
  }

  refresh() {
    window.location.reload();
  }

  reserve(content) {
    this.submitted = true;
    {
      if (this.reserving.invalid) {
        return;
      }
      console.log(this.reserving.value);
      // console.log(this.reserving.get('data').value)
      var data = this.reserving.value;
      let myDate = new Date(data.scheduleDate.year, data.scheduleDate.month - 1, data.scheduleDate.day);
      data.scheduleDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
      console.log(data);
      this.seatservice.reserveseat(this.reserving.value).subscribe(data => {
        if (data.apiResponse.id == 0) {
          this.seatservice.reserveseat(this.reserving.value);
          this.message = 'การจองสำเร็จ';
          this.submessage = 'ระบบได้ทำการจองให้ท่านเรียบร้อยแล้ว';
          // alert(data.apiResponse.desc)
          // this.refreshInterval$.subscribe(() => {
          this.open(content);
          // window.location.reload();
          // console.log('ss')
          // }
          // );
        } else if (data.apiResponse.desc == 'WRONG_USERNAME_OR_PASSWORD') {
          this.message = 'การจองไม่สำเร็จ';
          this.submessage = 'ตรวจสอบการสะกด User และ Password อีกครั้ง';
          this.open(content);
          // alert(data.apiResponse.desc)
        } else if (data.apiResponse.desc == 'FOUND EXISTS RESERVED ON THIS PERIOD') {
          this.message = 'การจองไม่สำเร็จ';
          this.submessage = 'User นี้ได้ทำการจองในเวลานี้แล้ว';
          this.open(content);
          // alert(data.apiResponse.desc)
        } else if (data.apiResponse.desc == 'OVER_RESERVATION_LIMIT') {
          this.message = 'การจองไม่สำเร็จ';
          this.submessage = 'User ได้ทำการจองครบ 3 ชั่วโมงแล้ว';
          this.open(content);
        } else if (data.apiResponse.desc == 'SEAT ALREADY RESERVED BY OTHER PEOPLE') {
          this.message = 'การจองไม่สำเร็จ';
          this.submessage = 'ขออภัยเครื่องที่คุณเลือกได้ถูกจองก่อนหน้านี้แล้ว';
          this.open(content);
        } else {
          this.message = 'เกิดความผิดพลาด';
          this.submessage = 'กรุณาติดต่อเจ้าหน้าที่';
          this.error = data.apiResponse.desc;
          this.open(content);
          // alert(data.apiResponse.desc)
        }
      });
    }
  }

  ref() {
    window.location.reload();
  }
}
