import { Component, ViewChild, ElementRef, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SeatService } from 'src/app/seat.service';
import { fromEvent, interval } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Seat } from 'src/app/seat.model';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-setadmin',
  templateUrl: './setadmin.component.html',
  styleUrls: ['./setadmin.component.css']
})
export class SetadminComponent implements OnInit {

  reserving: FormGroup
  loginusers: FormGroup
  seat: Seat[] = []
  histories: any;
  selectedHistory: any;
  seatdata: any
  typedata: any
  perioddata: any;
  manageseat: any;
  mode = 0; //0 List,1-Edit
  lastSelectedSeat: any;
  isFormReady = false;
  seletedDate: any;
  minDate: any;
  scheduleDateform: any
  username: any;
  currentCursor = "default";
  currentSeat = '00000000-0000-0000-0000-000000000000';
  changeScheduleStatus(event) {
    console.log(event)
    this.minDate = event.target.value;
  }

  formscheduleDate() {
    this.scheduleDateform = this.formBuilder.group({
      scheduleDate: [this.minDate]
    })
  }
  constructor(private router: Router, private modalService: NgbModal, private formBuilder: FormBuilder,
    private seatservice: SeatService,
    public datePipe: DatePipe,
    private http: HttpClient) {

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    console.log(event);
    let myDate = current
    var newDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
    console.log(newDate);
    this.seletedDate = newDate;
    this.getFinancialData(newDate, this.username)
    // this.getFinancialData(newDate)
    // this.seatservice.getseatchange().subscribe(result => {
    //   console.log(result)
    //   this.seat = result.data;
    //   this.seatInfoForm = this.formBuilder.group(result.data[0]);

    //   this.manageseat = this.seat.find(f => f.id == this.seatservice.getData())
    //   console.log(this.manageseat)
    this.seatInfoForm = formBuilder.group({})
    this.isFormReady = true
    // });
  }
ref(){
  window.location.reload();
}
  getFinancialData(scheduleDates: string, username: string) {
    // console.log('Change Date');
    // this.seatservice.gethistolyuser(scheduleDates).subscribe(result => {
    // console.log(result);
    // this.mode = 2;
    // this.histories = result.data;
    // });
    var data = {
      'apiRequest': { 'action': 'get' }
      , data: [{
        userName: username,
        scheduleDate: scheduleDates
        // scheduleStatusId: "10000000-0000-0000-0000-000000000000"
      }]
    }
    console.log(data);
    this.http.post(environment.apiUrl + '/api/core/Reservation', data)
      .subscribe((res: any) => {
        this.histories = res.data
      })
  }
  changeFinanceDate(event: any) {
    console.log(event);
    let myDate = new Date(event.year, event.month - 1, event.day);
    var newDate = this.datePipe.transform(myDate, 'yyyy-MM-dd');
    console.log(newDate);
    this.seletedDate = newDate;
    this.getFinancialData(newDate, this.loginusers.value.userName)
  }

  @ViewChild("divCanvas") divCanvas: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  // @ViewChild('empty') empty: ElementRef;
  cx: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;
  ratio: number;
  seats: any;
  submitted = false

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    console.log(window.innerWidth);
    console.log(this.divCanvas.nativeElement.offsetWidth);
    console.log(this.divCanvas.nativeElement.offsetHeight);

    //if (this.firstLoad) {
    var screen_ratio = 2;
    if (window.innerWidth < 768) {
      screen_ratio = 1
    }
    else {
      screen_ratio = 2;
    }
    const size = window.innerHeight < window.innerWidth / screen_ratio ? window.innerHeight : window.innerWidth / screen_ratio;
    if (window.innerHeight < (window.innerWidth / screen_ratio)) {

      this.canvasEl.height = size; // window.innerHeight;
      this.canvasEl.width = size; // window.innerWidth/screen_ratio;
      this.ratio = window.innerHeight / 1000;
    }
    else {
      this.canvasEl.height = size;// window.innerHeight;
      this.canvasEl.width = size;// window.innerWidth/screen_ratio;
      this.ratio = (window.innerWidth / screen_ratio) / 1000;
    }


    //   this.firstLoad = false;
    // }
    // else {
    //   if (this.divCanvas.nativeElement.offsetWidth >= this.divCanvas.nativeElement.offsetHeight) {
    //     this.canvasEl.height = this.divCanvas.nativeElement.offsetHeight;
    //     this.canvasEl.width = this.divCanvas.nativeElement.offsetHeight;
    //     this.ratio = this.divCanvas.nativeElement.offsetHeight / 1000;
    //     console.log('height');
    //   }
    //   else {
    //     this.canvasEl.height = this.divCanvas.nativeElement.offsetWidth;
    //     this.canvasEl.width = this.divCanvas.nativeElement.offsetWidth;
    //     this.ratio = this.divCanvas.nativeElement.offsetWidth/ 1000;
    //     console.log('width');
    //   }
    //}


    //console.log(this.divCanvas.nativeElement.offsetWidth);
    if (this.seats) {
      this.seats.forEach(seat => {
        this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
      });
    }
    if (this.lastSelectedSeat) {
      console.log(this.lastSelectedSeat);
      //this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
      this.selectedDraw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio);
    }

    // this.ngAfterViewInit()
    console.log(this.ratio);
    // this.can
    // console.log(window.innerWidth);
    // this.innerWidth = window.innerWidth;
  }


  ngOnInit() {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');

    fromEvent(this.canvasEl, 'mousemove').subscribe(location => {
      // console.log("Moves");
      //console.log(this.ratio);
      var match = false;
      this.seats.forEach(element => {

        //console.log(element);
        if (!match) {
          if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
            (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
            if (this.currentSeat == element.id) {
              match = true;
            }
            else {
              this.currentSeat = element.id;
              if (this.currentCursor != 'pointer') {
                console.log(this.currentCursor);
                this.canvasEl.style.cursor = 'pointer';
                this.currentCursor = 'pointer';
                match = true;
                console.log('Change cursor to point');
              }
              else {
                console.log('Already pointer');
              }
            }

          }
          else {


          }
        }


      });
      if (!match) {
        if (this.currentCursor != 'default') {
          this.canvasEl.style.cursor = 'default';
          this.currentCursor = 'default';
          this.currentSeat = '00000000-0000-0000-0000-000000000000';
        }
      }

    }
    );

    fromEvent(this.canvasEl, 'mousedown').subscribe(location => {
      //console.log(location);
      //console.log(this.ratio);
      this.seats.forEach(element => {
        //console.log(element);

        if ((location as MouseEvent).layerX / this.ratio >= element.displayX - 5 && (location as MouseEvent).layerX / this.ratio <= element.displayX + 5 &&
          (location as MouseEvent).layerY / this.ratio >= element.displayY - 5 && (location as MouseEvent).layerY / this.ratio <= element.displayY + 5) {
          console.log(element);
          if (element.scheduleStatusId == '00000000-0000-0000-0000-000000000000') {
            this.seatInfoForm.patchValue({
              id: element.id,
              seatId: element.seatId,
              seatCode: element.seatCode,
              seatName: element.seatName,
              // scheduleDate: element.scheduleDate,
              // periodId: element.periodId,
              // periodCode: element.periodCode,
              // periodName: element.periodName,
              // availablePeriod: element.availablePeriod,
              scheduleStatusId: element.scheduleStatusId,
              scheduleStatusCode: element.scheduleStatusCode,
              scheduleStatusName: element.scheduleStatusName,
              displayX: element.displayX,
              displayY: element.displayY,
              // userName: element.userName
            })
            if (this.lastSelectedSeat) {
              this.draw(this.lastSelectedSeat.displayX * this.ratio, this.lastSelectedSeat.displayY * this.ratio, this.lastSelectedSeat);
            }
            this.selectedDraw(element.displayX * this.ratio, element.displayY * this.ratio);
            this.lastSelectedSeat = element;
          }
        }
      });
    }
    );
    this.onResize();
    this.periods();
    this.seatlist()
    this.create()
    this.seattypes()
    this.formscheduleDate()
    // this.seathistory()

  }

  create() {
    this.loginusers = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    })
  }
  selectedDraw(x, y) {


    // this.cx.fillStyle = '#000000';
    // this.cx.
    console.log(x);
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);

    this.cx.fillStyle = 'yellow'


    // if(draw.seatStatusId == '10000000-0000-0000-0000-000000000000'){
    // this.cx.fillStyle = 'gray';
    // } else {}
    // this.cx.fillStyle = 'green';
    // this.cx.fillStyle = 'green';
    this.cx.fill();
  }
  draw(x, y, draw) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    switch (draw.scheduleStatusId) {
      case '10000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'red'
        break;
      case '00000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = '#4fcc25'
        break;
      case '20000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'blue'
        break;
      case '30000000-0000-0000-0000-000000000000':
        this.cx.fillStyle = 'gray'
        break;
    }
    this.cx.fill();
  }
  removeDraw(x, y, draw) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = '#FFFFFF'
    this.cx.fill();
  }
  historyDraw(x, y) {
    this.cx.beginPath();
    this.cx.arc(x, y, 7 * this.ratio, 0, 2 * Math.PI);
    this.cx.fillStyle = '#FF0000'
    this.cx.fill();
  }
  // seard(date, period) {

  //   if (period != '') {
  //     var temPeriod = this.perioddata.find(x => x.id == period)
  //     var codeP: any = temPeriod.code
  //   } else {
  //     var codeP: any = ''
  //   }

  //   console.log(date, codeP)
  //   this.seatservice.getseatSearch(date, codeP).subscribe(result => {
  //     console.log(result)
  //     this.seats = result.data;
  //     this.seats.forEach(seat => {
  //       console.log(seat)
  //       this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
  //     });
  //   });
  // }

  // getSeatSchedule(date, period) {

  //   this.seatservice.getseatSearch(date, period).subscribe(result => {
  //     console.log(result)
  //     if (this.seats) {
  //       this.seats.forEach(seat => {
  //         //console.log(seat)
  //         this.removeDraw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
  //       });
  //     }
  //     this.seats = result.data;
  //     this.seats.forEach(seat => {
  //       console.log(seat);
  //       console.log(this.selectedHistory);
  //       if (seat.seatTypeId == this.selectedHistory.seatTypeId) {
  //         //console.log(seat)
  //         this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
  //       }
  //       if (seat.seatId == this.selectedHistory.seatId) {
  //         this.historyDraw(seat.displayX * this.ratio, seat.displayY * this.ratio);
  //       }
  //     });
  //   });
  // }

  getSeatSchedule(date, period, seatid) {
    this.seatservice.getseatSearchadmin(date, period, seatid).subscribe(result => {
      // console.log(result)
      if (this.seats) {
        this.seats.forEach(seat => {
          //console.log(seat)
          this.removeDraw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
        });
      }
      this.seats = result.data;
      this.seats.forEach(seat => {
        //console.log(seat);
        //console.log(this.selectedHistory);
        if (seat.seatTypeId == this.selectedHistory.seatTypeId) {
          //console.log(seat)
          this.draw(seat.displayX * this.ratio, seat.displayY * this.ratio, seat);
        }
        if (seat.seatId == this.selectedHistory.seatId) {
          this.historyDraw(seat.displayX * this.ratio, seat.displayY * this.ratio);
        }
      });
    });
  }

  //seatInfo:any;
  seatInfoForm: FormGroup;
  isSeatInfoReady = 0;
  showSeatInfo(history: any) {
    console.log(history);
    this.selectedHistory = history;
    this.seatInfoForm = this.formBuilder.group(history);
    this.isSeatInfoReady = 1;
    console.log(this.seatInfoForm);
    this.mode = 1;
    this.getSeatSchedule(history.scheduleDate, history.periodCode,history.seatScheduleId);
  }
  back() {
    this.mode = 0
  }

  butempty() {
    this.mode = 3
  }
  // update(history: any) {
  // this.mode = 1;
  // console.log(history)
  // this.seatservice.setData(history)
  // this.getSeatSchedule(history.scheduleDate, history.periodCode);
  // }

  // navigate
  admin() {
    return this.router.navigate(['admin'])
  }
  setadmin() {
    window.location.reload()
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  loginadmin() {
    return this.router.navigate(['loginadmin'])
  }
  login() {
    return this.router.navigate(['login'])
  }
  // save
  // seathistory(username: string) {
    // var data = {
    //   'apiRequest': { 'action': 'get' }
    //   , data: [{
    //     userName: username,
    //     scheduleStatusId: "10000000-0000-0000-0000-000000000000"
    //   }]
    // }
    // console.log(data);
    // this.http.post(environment.apiUrl + '/api/core/Reservation', data)
    //   .subscribe((res: any) => {
    //     this.histories = res.data

    //   })
  // }


  // cancleseat: FormGroup
  // isCancleSeatReady = 0;
  cancel_id: any
  cancel() {
    // var dialog = confirm(seat.seatName)
    // if (dialog == true) {
    this.seatservice.cancleseat(this.cancel_id).subscribe(result => {
      console.log(result);
      // alert('Cancel Data!')
      this.getFinancialData(this.seletedDate,this.loginusers.value.userName)
    });
    // }
  }
  get f() { return this.loginusers.controls; }

  messageloginhistory: any
  submessageloginhistory: any
  errorhistory: any

  loginhistory(content) {
    this.submitted = true;
    {
      if (this.loginusers.invalid) {
        return;
      }
      console.log(this.loginusers.value);
      // console.log(this.reserving.get('data').value)
      this.seatservice.login(this.loginusers.value).subscribe(data => {
        //console.log(data);
        if (data.apiResponse.id == 0) {
          // this.seathistory(this.loginusers.value.userName)
          this.messageloginhistory = 'Login สำเร็จ'
          this.submessageloginhistory = 'เข้าสู่ระบบเรียบร้อย'
          this.mode = 2;
          this.open(content)
          this.getFinancialData(this.seletedDate,this.loginusers.value.userName)
        }
        else if (data.apiResponse.desc == "WRONG_USERNAME_OR_PASSWORD") {
          this.messageloginhistory = 'Login ไม่สำเร็จ'
          this.submessageloginhistory = 'ตรวจสอบการสะกด User และ Password อีกครั้ง'
          this.open(content)
          // alert(data.apiResponse.desc)
        }
        else {
          this.messageloginhistory = 'เกิดความผิดพลาด'
          this.submessageloginhistory = 'กรุณาติดต่อเจ้าหน้าที่'
          this.errorhistory = data.apiResponse.desc
          this.open(content)
        }
      });
    }
  }

  messageloginemty: any
  submessageloginemty: any
  loginemty(content) {
    this.submitted = true;
    {
      if (this.loginusers.invalid) {
        return;
      }
      console.log(this.loginusers.value);
      // console.log(this.reserving.get('data').value)
      this.seatservice.loginemty(this.loginusers.value).subscribe(data => {
        //console.log(data);
        if (data.apiResponse.id == 0) {
          this.messageloginemty = 'ลงชื่อเข้าใช้งานสำเร็จ'
          this.submessageloginemty = 'ลงชื่อเข้าใช้งานเรียบร้อย'
          this.mode = 0
          this.open(content)
        }
        else if (data.apiResponse.desc == "WRONG_USERNAME_OR_PASSWORD") {
          this.messageloginemty = 'ลงชื่อเข้าใช้งานไม่สำเร็จ'
          this.submessageloginemty = 'ตรวจสอบการสะกด User และ Password อีกครั้ง'
          this.open(content)
        }
        // else
        // {
        // }
      });
      // this.empty.nativeElement
      // alert('success');
    }
  }

  logout() {
    window.location.reload();
  }

  open(content) {
    this.modalService.open(content);
  }
  delete(del, id) {
    console.log(id)
    this.cancel_id = id
    this.modalService.open(del);
  }
  changeSeat(content) {
    console.log(this.seatInfoForm.getRawValue());
    this.seatservice.changereserveseat(this.seatInfoForm.getRawValue()).subscribe(result => {
      console.log(result);
      // this.mode = 2;
      this.getFinancialData(this.seletedDate,this.loginusers.value.userName)
        this.open(content)
    });
  }
  seattypes() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/SeatType', data)
      .subscribe((res: any) => {
        this.typedata = res.data
        console.log(res.data);
      })
  }
  timedata = [{
    id: 1, hour: ['1']
  },
  {
    id: 2, hour: ['2']
  },
  {
    id: 3, hour: ['3']
  }
  ];

  periods() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Period', data)
      .subscribe((res: any) => {
        this.perioddata = res.data
        console.log(res.data);
      })
  }

  // seattypes() {
  //   var data = {
  //     'apiRequest': { 'action': 'list' }
  //   }
  //   this.http.post(environment.apiUrl + '/api/core/SeatType', data)
  //     .subscribe((res: any) => {
  //       this.typedata = res.data
  //       console.log(res.data);
  //     })
  // }
  seatlist() {
    var data = {
      'apiRequest': { 'action': 'list' }
    }
    this.http.post(environment.apiUrl + '/api/core/Seat', data)
      .subscribe((res: any) => {
        this.seatdata = res.data
        console.log(res.data);
      })
  }
  resetseat(reset, id) {
    console.log(id)
    this.reset_id = id
    this.modalService.open(reset);
  }
  reset_id: any
  reset() {
    this.seatservice.resetseat(this.reset_id).subscribe(result => {
      console.log(result);
      this.getFinancialData(this.seletedDate,this.loginusers.value.userName)
    });
  }
}
