import { Component, OnInit } from '@angular/core';
import { SeatService } from 'src/app/seat.service';
import { Seat } from 'src/app/seat.model';

@Component({
  selector: 'app-adminlist',
  templateUrl: './adminlist.component.html',
  styleUrls: ['./adminlist.component.css']
})
export class AdminlistComponent implements OnInit {
// seat: Seat[]=[]
  constructor(private seatservice:SeatService) { }

  ngOnInit() {
    // this.seatservice.getallseat().subscribe(result => {
    //   console.log(result);
    //   this.seat = result.data;
    //   this.seat = this.seat.filter(x => x.scheduleStatusName == 'Reserved')
    // });
  }

}
