import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Admin } from 'src/app/admin.model';
import { AuthService } from 'src/app/authen.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editadmin',
  templateUrl: './editadmin.component.html',
  styleUrls: ['./editadmin.component.css']
})
export class EditadminComponent implements OnInit {

  admin: Admin[] = [];
  editregisterform: FormGroup;
  admindata: any;
  isFormReady = false;
  isAdmin: any;

  constructor(private router: Router,private modalService: NgbModal, private formBuilder: FormBuilder, private adminservice: AdminService
    , private http: HttpClient,
    private authenService: AuthService) {
    // this.isAdmin = this.authenService.isAdmin;
    console.log(this.authenService.userName);
    // console.log(this.authenService.isAdmin);

    this.adminservice.getadmin().subscribe(result => {
      console.log(result)
      this.admin = result.data;
      this.editregisterform = this.formBuilder.group(result.data[0]);
      console.log(this.editregisterform);
      this.editregisterform.patchValue(result.data[0]);
      console.log(this.editregisterform);

      this.admindata = this.admin.find(f => f.id == this.adminservice.getData())
      console.log(this.admindata)
      this.isFormReady = true;
    });
  }

  ngOnInit() {
  }
  update() {
    console.log(this.editregisterform.getRawValue());
    this.adminservice.updateuseradmin(this.editregisterform.getRawValue()).subscribe(result => {
      console.log(result);
      this.router.navigate(['home/adminmanage'])
      alert('Success');
    });
  }
  createadmin() {
    return this.router.navigate(['home/createadmin'])
  }
  adminpage() {
    return this.router.navigate(['home/admin'])
  }
  device() {
    return this.router.navigate(['home/device'])
  }
  adminmanage() {
    return this.router.navigate(['home/adminmanage'])
  }
  closeschedule() {
    return this.router.navigate(['home/closeschedule'])
  }
  reservingadminmanage() {
    return this.router.navigate(['home/reservingadminmanage'])
  }
  logout() {
    return this.router.navigate(['login'])
  }
  loginuser() {
    return this.router.navigate(['loginuser'])
  }
  open(content) {
    this.modalService.open(content);
  }
}
