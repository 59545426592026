import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { ZonebComponent } from './admin/zoneb/zoneb.component';
import { ZonecComponent } from './admin/zonec/zonec.component';
import { ZonedComponent } from './admin/zoned/zoned.component';
import { ZoneeComponent } from './admin/zonee/zonee.component';
import { ZonefComponent } from './admin/zonef/zonef.component';
import { ZonegComponent } from './admin/zoneg/zoneg.component';
import { ZonepComponent } from './admin/zonep/zonep.component';
import { ZonesComponent } from './admin/zones/zones.component';
import { SetadminComponent } from './admin/setadmin/setadmin.component';
import { LoginuserComponent } from './admin/loginuser/loginuser.component';
import { DeviceComponent } from './device/device.component';
import { ManagedeviceComponent } from './device/managedevice/managedevice.component';
import { CreatedeviceComponent } from './device/createdevice/createdevice.component';
import { MaccomComponent } from './device/maccom/maccom.component';
import { ScannerComponent } from './device/scanner/scanner.component';
import { EmptyComponent } from './device/empty/empty.component';
import { AdminmanageComponent } from './adminmanage/adminmanage.component';
import { CreateadminComponent } from './adminmanage/createadmin/createadmin.component';
import { EditadminComponent } from './adminmanage/editadmin/editadmin.component';
import { ReservingadminmanageComponent } from './reservingadminmanage/reservingadminmanage.component';
import { LoginComponent } from './login/login.component';
import { ClosescheduleComponent } from './closeschedule/closeschedule.component';
import { CloseschedulemanageComponent } from './closeschedule/closeschedulemanage/closeschedulemanage.component';
import { ReservationsummaryComponent } from './reservationsummary/reservationsummary.component';
import { ReservingmanageseatscheduleComponent } from './reservingmanageseatschedule/reservingmanageseatschedule.component';
import { AuthGuard } from './authen.guard';
import {ClosescheduleDoComponent} from './closeschedule/closeschedule-do/closeschedule-do.component';


// import { AdminlistComponent } from './adminmanage/adminlist/adminlist.component';
// import { LoginadminComponent } from './admin/loginadmin/loginadmin.component';
// import { ManageComponent } from './admin/manage/manage.component';
// import { AdminmanageseatComponent } from './reservingadminmanage/adminmanageseat/adminmanageseat.component';
// import { ZonestatusComponent } from './admin/zonestatus/zonestatus.component';
// import { SeatstatusComponent } from './admin/seatstatus/seatstatus.component';
// import { EditseatComponent } from './editseat/editseat.component';

const routes: Routes = [
  { path: '', redirectTo: '/loginuser', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: 'reservingadminmanage', component: ReservingadminmanageComponent },
          { path: 'admin', component: AdminComponent },
          { path: 'zoneb', component: ZonebComponent },
          { path: 'zonec', component: ZonecComponent },
          { path: 'zoned', component: ZonedComponent },
          { path: 'zonee', component: ZoneeComponent },
          { path: 'zonef', component: ZonefComponent },
          { path: 'zoneg', component: ZonegComponent },
          { path: 'zonep', component: ZonepComponent },
          { path: 'zones', component: ZonesComponent },
          { path: 'device', component: DeviceComponent },
          { path: 'managedevice', component: ManagedeviceComponent },
          { path: 'createdevice', component: CreatedeviceComponent },
          { path: 'maccom', component: MaccomComponent },
          { path: 'scanner', component: ScannerComponent },
          { path: 'empty', component: EmptyComponent },
          { path: 'closeschedule', component: ClosescheduleComponent },
          // { path: 'closeschedulemanage', component: CloseschedulemanageComponent },
          { path: 'closeschedulemanage', component: ClosescheduleDoComponent },
          { path: 'reservationsummary', component: ReservationsummaryComponent },
          { path: 'reservingmanageseatschedule', component: ReservingmanageseatscheduleComponent },
          { path: 'adminmanage', component: AdminmanageComponent },
          { path: 'createadmin', component: CreateadminComponent },
          { path: 'editadmin', component: EditadminComponent },
        ]
      }
      // { path: 'reservingadminmanage', component: ReservingadminmanageComponent },
    ]
  },
  // { path: 'home', component: HomeComponent },
  { path: 'setadmin', component: SetadminComponent },
  { path: 'loginuser', component: LoginuserComponent },
  // { path: 'login', component: LoginComponent },



  // {path:'adminlist', component:AdminlistComponent},
  // {path:'loginadmin', component:LoginadminComponent},
  // {path:'adminmanageseat', component:AdminmanageseatComponent},
  // {path:'manage', component:ManageComponent},
  // {path:'zonestatus', component:ZonestatusComponent},
  // {path:'seatstatus', component:SeatstatusComponent},
  // {path:'editseat', component:EditseatComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
