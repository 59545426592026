import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

// import { AppSettings } from '../settings/app-settings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  userName = '';
  isAdmin = false;
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private httpClient: HttpClient) {

  }

  setLogin(name: string, status: boolean) {
    this.isLoggedIn = true;
    this.userName = name;
    this.isAdmin = status;
    const auth =  {
      isLoggedIn: true,
      userName: name,
      isAdmin: status,
    };
    localStorage.setItem('reserving', JSON.stringify(auth));
  }

  logout(): void {
    this.isLoggedIn = false;
  }
}
